import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import checkImage from './checkImage.json'
import checkCloseImage from './checkCloseImage.json'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderMapTime = (data, timeMap) => {
  console.log('checkImage', checkImage)
  const content = []
  console.log(data.length)
  _.map(timeMap, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (time === eachData?.TimeStart) {
          indexTime = true
          content.push(
            eachData?.subForm_name === 'SECTION A1-5'
              ? [
                  //SECTION A1-5
                  { text: eachData?.TimeStart || '', style: 'tableContent' },
                  {
                    image:
                      eachData?.A1.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.A1.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.A1.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.A1.HOUR || '-', style: 'tableContent' },

                  {
                    image:
                      eachData?.A2.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.A2.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.A2.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.A2.HOUR || '-', style: 'tableContent' },

                  {
                    image:
                      eachData?.A3.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.A3.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.A3.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.A3.HOUR || '-', style: 'tableContent' },

                  {
                    image:
                      eachData?.A4.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.A4.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.A4.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.A4.HOUR || '-', style: 'tableContent' },

                  {
                    image:
                      eachData?.A5.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.A5.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.A5.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.A5.HOUR || '-', style: 'tableContent' },
                ]
              : [
                  //SECTION B1-5
                  { text: eachData?.TimeStart || '', style: 'tableContent' },
                  {
                    image:
                      eachData?.B1.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.B1.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.B1.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.B1.HOUR || '-', style: 'tableContent' },

                  {
                    image:
                      eachData?.B2.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.B2.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.B2.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.B2.HOUR || '-', style: 'tableContent' },

                  {
                    image:
                      eachData?.B3.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.B3.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.B3.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.B3.HOUR || '-', style: 'tableContent' },

                  {
                    image:
                      eachData?.B4.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.B4.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.B4.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.B4.HOUR || '-', style: 'tableContent' },

                  {
                    image:
                      eachData?.B5.เวลาเปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    image:
                      eachData?.B5.เวลาปิด === true
                        ? checkImage?.image
                        : checkCloseImage?.image,
                    fit: [9, 9],
                    alignment: 'center',
                  },
                  {
                    text: eachData?.B5.กระแส || '-',
                    tyle: 'tableContent',
                    fontSize: 10,
                    margin: [0, -3, 0, -2],
                    alignment: 'center',
                  },
                  { text: eachData?.B5.HOUR || '-', style: 'tableContent' },
                ]
          )
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const FM_PD_008 = async (form, formInput, subForm, system, date) => {
  const colors = '#dddddd'

  const timeMap = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
  ]
  //find data for map in table
  const dataA = _.filter(formInput?.arr, {
    subForm_name: 'SECTION A1-5',
  })
  const dataB = _.filter(formInput?.arr, {
    subForm_name: 'SECTION B1-5',
  })
  const TableBodyA = renderMapTime(dataA, timeMap)
  const TableBodyB = renderMapTime(dataB, timeMap)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [10, 50, 10, 0],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 5, 10, 0],
        style: 'tableExample',
        table: {
          widths: ['24.6%', '47.6%', '18.5%', '9.3%'],
          body: [
            [
              {
                image: 'logo_imge',
                fit: [20, 20],
                alignment: 'center',
                margin: [0, 2, 0, -2],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''} - (${form?.docNo || ''})`,
                alignment: 'center',
                bold: true,
                fontSize: 13,
                margin: [0, 0, 0, -3],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                alignment: 'center',
                colSpan: 2,
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'THAI TALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'AngsanaNew',
                fontSize: 10,
                border: [true, false, true, true],
              },
              {
                text: `วันที่ ${dayjs(date).format(
                  'DD เดือน MMMM พ.ศ. BBBB '
                )}`,
                alignment: 'center',
                style: 'header',
                border: [true, false, true, true],
              },
              {
                text: `บังคับใช้ : ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                style: 'header',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '6.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
          ],
          body: [
            [
              //eachData 1
              {
                text: '',
                style: 'headerTable',
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: 'A1',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {
                text: 'A2',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {
                text: 'A3',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {
                text: 'A4',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {
                text: 'A5',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
            ],
            [
              //eachData 2
              {
                text: 'เวลาทำงาน',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
            ],
            [
              //eachData 3
              {
                text: '',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
            ],
            ...TableBodyA,
            //...TableBody4_6,
          ],
        },
      },
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '6.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
            '4%',
            '4%',
            '5.5%',
            '5.25%',
          ],
          body: [
            [
              //eachData 1
              {
                text: '',
                style: 'headerTable',
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: 'B1',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {
                text: 'B2',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {
                text: 'B3',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {
                text: 'B4',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {
                text: 'B5',
                style: 'headerTable',
                fillColor: colors,
                alignment: 'center',
                colSpan: 4,
                fontSize: 12,
                border: [true, true, true, true],
              },
              {},
              {},
              {},
            ],
            [
              //eachData 2
              {
                text: 'เวลาทำงาน',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'เวลา',
                colSpan: 2,
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {},
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'HOUR',
                rowSpan: 2,
                margin: [0, 2],
                style: 'headerTable',
                border: [true, true, true, true],
              },
            ],
            [
              //eachData 3
              {
                text: '',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: 'เปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: 'ปิด',
                style: 'headerTable',
                border: [true, true, true, true],
              },
              {
                text: '(45-70 A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
            ],
            ...TableBodyB,
            //...TableBody4_6,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'ผู้รายงานกะ A 1.ลงชื่อ ................................................. 2.ลงชื่อ................................................',
                alignment: 'left',
                style: 'tableContent',
                margin: [0, 2, 0, -2],
                border: [true, true, true, false],
              },
              {
                text: 'ผู้รายงานกะ B 1.ลงชื่อ ................................................. 2.ลงชื่อ................................................',
                style: 'tableContent',
                alignment: 'left',
                margin: [0, 2, 0, -2],
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: '    (.......................................)            (.......................................)  ',
                alignment: 'center',
                style: 'tableContent',
                border: [true, false, true, false],
              },
              {
                text: '    (.......................................)            (.......................................)  ',
                alignment: 'center',
                style: 'tableContent',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: 'หัวหน้างานกะ A ลงชื่อ................................................................',
                alignment: 'left',
                style: 'tableContent',
                border: [true, false, true, false],
              },
              {
                text: 'หัวหน้างานกะ B ลงชื่อ................................................................',
                style: 'tableContent',
                alignment: 'left',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: '(.........................................................)   ',
                alignment: 'center',
                style: 'tableContent',
                margin: [-100, -2, 0, -2],
                border: [true, false, true, true],
              },
              {
                text: '(.........................................................)    ',
                alignment: 'center',
                style: 'tableContent',
                margin: [-100, -2, 0, -2],
                border: [true, false, true, true],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,

        alignment: 'center',
        fontSize: 7,
      },
      header: {
        bold: true,
        fontSize: 12,
        margin: [0, 0, 0, -3],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [-3, -2, -3, -1],
        fontSize: 10,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 10,
        margin: [0, -2, 0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_008
