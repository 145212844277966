import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Code,
  FormControl,
  Input,
  Select,
  Checkbox,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import * as actions from '../../redux/actions'
import config from '../../config'

export default function SensorComponent({
  stationData,
  sensorData,
  placeToEdit = 'station',
}) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit, watch } = useForm()
  const dispatch = useDispatch()
  // Merged the Sensor Data and Make new Station Data to Database
  const prepareUpdateDatabase = (data) => {
    const selectedSensor = _.find(
      stationData.sensors,
      (sensor) => sensor._id === sensorData._id
    )
    const selectedSensorIndex = _.findIndex(
      stationData.sensors,
      (sensor) => sensor._id === sensorData._id
    )
    const mergedSensorData = _.merge(selectedSensor, data)
    let editedStation = stationData
    editedStation.sensors[selectedSensorIndex] = mergedSensorData

    // is on Template or on Station FIXME: Magic Code

    if (placeToEdit === 'template') {
      console.log('This is Template not station')
      dispatch(actions.stationTemplatePut(stationData._id, editedStation))
    } else {
      console.log('This is Station')
      dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
        dispatch(actions.stationAll())
      })
    }

    setIsEditorMode(false)
    window.location.reload()
  }

  const prepateDeleteSensor = (sensorId) => {
    let confirm = window.confirm('ยืนยันการลบเซ็นเซอร์')
    if (confirm) {
      const selectedSensorIndex = _.findIndex(
        stationData.sensors,
        (sensor) => sensor._id === sensorId
      )

      let editedStation = stationData
      if (editedStation.sensors) {
        console.log('Original Sensor Stack', editedStation.sensors)
        editedStation.sensors.splice(selectedSensorIndex, 1)
        console.log('SPLICED')
        console.log('New Sensor Stack', editedStation.sensors)
      }

      // is on Template or on Station FIXME: Magic Code
      if (placeToEdit === 'template') {
        console.log('This is Template not station')
        dispatch(actions.stationTemplatePut(stationData._id, editedStation))
      } else {
        console.log('This is Station')
        dispatch(actions.stationPut(stationData._id, editedStation)).then(
          () => {
            dispatch(actions.stationAll())
          }
        )
      }
      setIsEditorMode(false)
    }
  }

  const editorModeComponent = () => (
    <div>
      <FormControl>
        <label className="text-sm font-bold"> {t('sensor.name')} </label>
        <Input
          type="text"
          name="name"
          size="sm"
          defaultValue={sensorData.name}
          ref={register}
        />
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold"> {t('sensor.sensorDesc')} </label>
        <Input
          type="text"
          name="description"
          size="sm"
          defaultValue={sensorData.description}
          ref={register}
        />
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold">Iot Reference String</label>
        <Input
          type="text"
          name="refString"
          size="sm"
          defaultValue={sensorData.refString}
          ref={register}
        />{' '}
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold">
          {' '}
          {t('sensor.sensorFeature')}{' '}
        </label>
        {sensorData.sensorType === 'BOOLEAN' && (
          <Select
            defaultValue={sensorData.gaugeType}
            ref={register}
            size="sm"
            name="gaugeType"
          >
            <option value="">{t('utility.noFeature')}</option>
            {_.map(
              config.ComponentType.SensorGaugeType,
              (_gaugeType, index) => (
                <option value={_gaugeType.value} key={index}>
                  {_gaugeType.describe}
                </option>
              )
            )}
          </Select>
        )}

        {sensorData.sensorType === 'NUMBER' && (
          <Select
            defaultValue={sensorData.gaugeType}
            ref={register}
            size="sm"
            name="gaugeType"
          >
            {_.map(
              config.ComponentType.NumberSensorType,
              (_gaugeType, index) => (
                <option value={_gaugeType.value} key={index}>
                  {_gaugeType.describe}
                </option>
              )
            )}
          </Select>
        )}
      </FormControl>

      {sensorData.sensorType === 'BOOLEAN' && (
        <>
          <FormControl>
            <label className="text-sm font-bold">
              Iot Control Reference String
            </label>
            <Input
              type="text"
              name="controlRefString"
              size="sm"
              defaultValue={sensorData.controlRefString}
              ref={register}
            />{' '}
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold">
              {' '}
              {t('control.pulseControl')}{' '}
            </label>
            <Select
              defaultValue={sensorData.controlWithPulse}
              ref={register}
              size="sm"
              name="controlWithPulse"
            >
              <option value={true}>{t('control.yes')}</option>
              <option value={false}>{t('control.no')}</option>
            </Select>
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold">
              {t('sensor.defaultValue')}
            </label>
            <Select
              defaultValue={sensorData.booleanDefaultValue}
              ref={register}
              size="sm"
              name="booleanDefaultValue"
            >
              <option value={Boolean(true)}>True</option>
              <option value={Boolean(false)}>False</option>
            </Select>
          </FormControl>
        </>
      )}

      {sensorData.sensorType === 'NUMBER' && (
        <>
          <FormControl>
            <label className="text-sm font-bold">
              {t('sensor.unitOfValue')}{' '}
            </label>
            <Input
              type="text"
              name="valueUnit"
              size="sm"
              defaultValue={sensorData.valueUnit}
              ref={register}
            />{' '}
          </FormControl>
          <FormControl>
            <label className="text-sm font-bold">
              {t('sensor.defaultValue')}{' '}
            </label>
            <Input
              type="number"
              name="numericDefaultValue"
              size="sm"
              defaultValue={sensorData.numericDefaultValue}
              ref={register}
            />{' '}
          </FormControl>
        </>
      )}
    </div>
  )

  const displayModeComponent = () => (
    <div>
      <p className="text-sm "> {sensorData.description} </p>
      <ul>
        {sensorData.gaugeType !== '' ? (
          <li>
            <span className="text-sm font-semibold text-gray-700">
              {t('sensor.sensorFeature')} :{' '}
            </span>
            <span className="text-sm font-semibold text-blue-700">
              {sensorData.gaugeType === ''
                ? 'ไม่มีลักษณะพิเศษ'
                : sensorData.gaugeType}
            </span>
          </li>
        ) : (
          ''
        )}

        {sensorData.sensorType === 'BOOLEAN' && (
          <>
            <li>
              <span className="text-sm font-semibold text-gray-700">
                {t('control.pulseControl')} :{' '}
              </span>
              <span className="text-sm font-semibold text-blue-700">
                {sensorData.controlWithPulse === true
                  ? t('control.yes')
                  : t('control.no')}
              </span>
            </li>
            <li>
              <span className="text-sm font-semibold text-gray-700">
                {t('sensor.defaultValue')}:{' '}
              </span>
              {sensorData.booleanDefaultValue === true ? (
                <div>
                  <Badge colorScheme="green"> True </Badge>
                </div>
              ) : (
                <div>
                  {' '}
                  <Badge colorScheme="red"> False </Badge>
                </div>
              )}
            </li>{' '}
            <li>
              <span className="text-sm font-semibold text-gray-700">
                IoT Control Reference String :{' '}
              </span>
              <Code> {sensorData.controlRefString}</Code>
            </li>
          </>
        )}

        <li>
          <span className="text-sm font-semibold text-gray-700">
            IoT Reference String :{' '}
          </span>
          <Code> {sensorData.refString}</Code>
        </li>
      </ul>
    </div>
  )

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {' '}
              {sensorData.name}{' '}
              <Badge colorScheme="teal" size="sm">
                {sensorData.sensorType}{' '}
              </Badge>{' '}
            </h5>
          </div>

          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>{' '}
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsEditorMode(true)}
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepateDeleteSensor(sensorData._id)}
                >
                  {t('utility.delete')}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="flex gap-3">
          <div>
            {isEditorMode === true
              ? editorModeComponent()
              : displayModeComponent()}
          </div>
        </div>
      </form>
    </Box>
  )
}
