import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray, Controller, useWatch } from 'react-hook-form'
import _, { each } from 'lodash'
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Switch,
  Box,
  Link as LinkUI,
  Input,
  Stack,
} from '@chakra-ui/react'

import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'

import { useTranslation } from 'react-i18next'
import { Row } from 'reactstrap'
// import hash from "object-hash";

export default function EditNotify() {
  const { t } = useTranslation()
  const stations = useSelector((state) => state.station)

  const dispatch = useDispatch()
  const history = useHistory()
  const [allowedDashboard, setAllowedDashboard] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const me = useSelector((state) => state.me)

  const { register, handleSubmit, reset, watch } = useForm()

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => {
      dispatch(actions.systemInfoGet()).then(() => {
        setIsLoading(true)
      })
    })
    return () => {}
  }, [])

  useEffect(() => {
    if (me && me._id) {
      if (_.size(stations.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = stations.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            stations.arr,
            (station, index) => station._id === me.duty[index]
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [stations, me])

  const onSubmit = async (data) => {
    let confirm = window.confirm('อัพเดทข้อมูล')
    console.log('Add token - ', data)
    const payload = {
      lineToken: data.lineToken,
    }
    if (confirm) {
      await dispatch(actions.stationPut(data.stationId, payload))
      alert('บันทึกสำเร็จ')
    }
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 rounded">
      {/* <div className="flex justify-between p-4"></div> */}
      {/* <div className="p-4 w-full">
            <NotifyEnableControls systemInfo={systemInfo} />
          </div> */}
      <div className="p-4 w-full">
        {_.size(stations.arr) ? (
          <>
            {' '}
            {_.map(allowedDashboard, (station, index) => (
              <Box
                padding="4"
                borderRadius="lg"
                borderWidth="1px"
                className="mb-2"
                key={station._id}
              >
                <div className="flex flex-row">
                  <div className="flex flex-col w-3/5 m-2 p-1">
                    {' '}
                    <h3 className="font-bold text-xl font-sans">
                      <i className="fas fa-search text-sm"> </i> {station.name}{' '}
                    </h3>
                    <h5 className="text-base text-gray-700 font-sans">
                      {' '}
                      {station.description}
                    </h5>
                  </div>
                  <div className="flex flex-row items-center self-center w-2/5">
                    <form>
                      <Stack spacing={2} direction={'row'}>
                        <Input
                          key={index}
                          placeholder="ใส่ Token ของสถานี"
                          type="text"
                          name={station._id}
                          defaultValue={station.lineToken}
                          ref={register}
                        />
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          size="sm"
                          type="button"
                          onClick={() =>
                            onSubmit({
                              stationId: station?._id,
                              lineToken: watch(station._id),
                            })
                          }
                        >
                          บันทึก
                        </Button>
                      </Stack>
                    </form>
                  </div>
                  {/* <div className="flex flex-col items-center self-center w-1/5">
                  </div> */}
                </div>
              </Box>
            ))}
          </>
        ) : (
          <div>{t('utility.empty')}</div>
        )}
      </div>
    </div>
  )
}
