import { BoilerType } from './BoilerType'
import { PumpType } from './PumpType'
import { ConveyorType } from './ConveyorType'
import { FlowMeterType } from './FlowMeterType'
import { MotorType } from './MotorType'
import { PipeType } from './PipeType'
import { FilterType } from './FilterType'

export { BoilerType, PumpType }
export default {
  BoilerType,
  PumpType,
  ConveyorType,
  FlowMeterType,
  MotorType,
  PipeType,
  FilterType,
}
