import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Link as LinkUI, Box } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Link, useParams } from 'react-router-dom'
import ReactApexChart from 'apexcharts'
import { RE } from 'country-flag-icons/react/3x2'
import OilBarrelIcon from '@mui/icons-material/OilBarrel'
import InsightsIcon from '@mui/icons-material/Insights'
import DataUsageIcon from '@mui/icons-material/DataUsage'
// import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'

import LossChart from './LossChart'
import LossOverChart from './LossOverChart'
import VolumeChart from './VolumeChart'
import YieldCumulative from './YieldCumulative'
import ElectricalUnit from './ElectricalUnit'
import GasgenUnit from './GasgenUnit'
import SteamUnit from './SteamUnit'
import FuelUnit from './FuelUnit'
import ElecUnitBiomass from './ElecUnitBiomass'
import FetchDataQC from './FetchDataQC'
import DataFormInput from './DataFormInput'
import CalAVG from './CalAVG'
import CalCumulative from './CalCumulative'
import LossOverOil from './LossOverOil'
import { right } from '@popperjs/core'
import { format } from 'mathjs'

export default function DisplayData({
  startTime,
  endTime,
  oilLoss,
  yieldCpo,
  oilLossAll,
  yieldAll,
  biogasAll,
  biomassAll,
  totalElectric,
  gasUsed,
  BiomassLog,
  findSumSteam,
  sumSteam,
  fuelUsed,
  AllFuel,
  electricGen,
  electricGenForm,
}) {
  //console.log('biogasAll[0]?.GenMeter ', biogasAll[0]?.GenMeter)
  // console.log('Biomass  ', AllFuel)
  // const [valueOilLoss, setValueOilLoss] = useState(

  const [valueLossFiber, setValueLossFober] = useState(
    DataFormInput(oilLossAll, 'OilLossFiber', startTime, endTime)
  )
  const [valueLossOverFiber, setValueLossOverFiber] = useState(
    LossOverOil(oilLossAll, yieldAll, 'OilLossOverFiber', startTime, endTime)
  )
  const [valueLossOverDecanter, setValueLossOverDecanter] = useState(
    LossOverOil(oilLossAll, yieldAll, 'OilLossOverDecanter', startTime, endTime)
  )
  const [valueLossOverWaste, setValueLossOverWaste] = useState(
    LossOverOil(oilLossAll, yieldAll, 'OilLossOverWaste', startTime, endTime)
  )
  //console.log('Over fiber ', oilLossAll, yieldAll)
  const [valueLossDecanter, setValueLossDecanter] = useState(
    DataFormInput(oilLossAll, 'OilLossDecanter', startTime, endTime)
  )
  const [valueLossWaste, setValueLossWaste] = useState(
    DataFormInput(oilLossAll, 'OilLossWastewater', startTime, endTime)
  )

  const [valueFFB, setValueFFB] = useState(
    DataFormInput(yieldAll, 'PalmUsed', startTime, endTime)
  )
  const [valueCPO, setValueCPO] = useState(
    DataFormInput(yieldAll, 'CPO', startTime, endTime)
  )

  const [valueYieldCPO, setValueYieldCPO] = useState(
    DataFormInput(yieldAll, 'Yield', startTime, endTime)
  )
  const [valueElectric, setValueElectric] = useState(
    DataFormInput(biogasAll, 'Total 1,2', startTime, endTime)
  )
  const [valueGas, setValueGas] = useState(
    DataFormInput(biogasAll, 'มิเตอร์ก๊าซที่ใช้ผลิต', startTime, endTime)
  )

  const [valueSumSteam, setValueSumSteam] = useState(
    FetchDataQC(BiomassLog, sumSteam?._id)
  )
  const [valueFindSumSteam, setValueFindSumSteam] = useState(
    DataFormInput(findSumSteam, 'SumSteam', startTime, endTime)
  )

  const [valueElectricGen, setValueElectricGen] = useState(
    FetchDataQC(BiomassLog, electricGen?._id)
  )
  const [valueFuel, setValueFuel] = useState(
    DataFormInput(AllFuel, 'QuantityPalm', startTime, endTime)
  )
  const [valueGenElectric, setValueGenElectric] = useState(
    DataFormInput(electricGenForm, 'ProductE', startTime, endTime)
  )

  return (
    <div>
      <Box
        borderRadius="lg"
        borderWidth="1px"
        padding="4"
        className="justify mb-4"
      >
        <h1 className="flex justify-between p-4 xl:text-xl">
          {'Production'}
          <Link to={'/summarize/detail'}>
            <Button colorScheme="blue" size="sm" type="button">
              ดูรายละเอียดเพิ่มเติม
            </Button>
          </Link>
        </h1>
        <h1 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/detail'}>
            <LinkUI>
              {' '}
              <OilBarrelIcon />
              {' ปริมาณการสูญเสียน้ำมันทั้งหมด : '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {/* {oilLoss?.numericStatus.toFixed(2)} */}
                {oilLossAll?.length === 0
                  ? oilLoss?.numericStatus.toFixed(2)
                  : (
                      parseFloat(CalAVG(oilLossAll, 'OilLossFiber')) +
                      parseFloat(CalAVG(oilLossAll, 'OilLossDecanter')) +
                      parseFloat(CalAVG(oilLossAll, 'OilLossWastewater'))
                    ).toFixed(2)}
              </span>
              {' ton/d'}
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {oilLossAll?.length === 0
                  ? dayjs(oilLoss?.lastDate).locale('th').format('DD MMM BBBB')
                  : dayjs(startTime).locale('th').format('DD MMM BBBB') +
                    ' ' +
                    'ถึง' +
                    ' ' +
                    dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
        </h1>
        <LossChart
          valueFiberLoss={valueLossFiber}
          valueDecanterLoss={valueLossDecanter}
          valueWasteLoss={valueLossWaste}
        />
        <h1 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/detail'}>
            <LinkUI>
              {' '}
              <OilBarrelIcon />
              {' ปริมาณการสูญเสียน้ำมันเกินค่ามาตรฐานทั้งหมด : '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {/* {oilLoss?.numericStatus.toFixed(2)} */}
                {oilLossAll?.length === 0
                  ? oilLoss?.numericStatus.toFixed(2)
                  : (
                      CalAVG(valueLossOverFiber, 'OilLossOverFiber') +
                      CalAVG(valueLossOverDecanter, 'OilLossOverDecanter') +
                      CalAVG(valueLossOverWaste, 'OilLossOverWastewater')
                    ).toFixed(2)}
              </span>
              {' ton/d'}
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {oilLossAll?.length === 0
                  ? dayjs(oilLoss?.lastDate).locale('th').format('DD MMM BBBB')
                  : dayjs(startTime).locale('th').format('DD MMM BBBB') +
                    ' ' +
                    'ถึง' +
                    ' ' +
                    dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
        </h1>
        <LossOverChart
          valueFiberLoss={valueLossOverFiber}
          valueDecanterLoss={valueLossOverDecanter}
          valueWasteLoss={valueLossOverWaste}
        />
        <h2 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/detail'}>
            <LinkUI>
              {' '}
              <InsightsIcon />
              {' ปริมาณ Yield ที่ได้ : '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {/* {yieldCpo?.numericStatus.toFixed(2)} */}
                {oilLossAll?.length === 0
                  ? yieldCpo?.numericStatus.toFixed(2)
                  : CalAVG(yieldAll, 'Yield').toFixed(2)}
              </span>
              {' %'}
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {oilLossAll?.length === 0
                  ? dayjs(yieldCpo?.lastDate).locale('th').format('DD MMM BBBB')
                  : dayjs(startTime).locale('th').format('DD MMM BBBB') +
                    ' ' +
                    'ถึง' +
                    ' ' +
                    dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
          <VolumeChart
            valueFFB={valueFFB}
            valueCPO={valueCPO}
            valueYieldCPO={valueYieldCPO}
          />
        </h2>
        <h2 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/detail'}>
            <LinkUI>
              {' '}
              <InsightsIcon />
              {' ปริมาณสะสม Yield ที่ได้ : '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {/* {yieldCpo?.numericStatus.toFixed(2)} */}
                {oilLossAll?.length === 0
                  ? yieldCpo?.numericStatus.toFixed(2)
                  : CalAVG(yieldAll, 'Yield').toFixed(2)}
              </span>
              {' %'}
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {oilLossAll?.length === 0
                  ? dayjs(yieldCpo?.lastDate).locale('th').format('DD MMM BBBB')
                  : dayjs(startTime).locale('th').format('DD MMM BBBB') +
                    ' ' +
                    'ถึง' +
                    ' ' +
                    dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
          <YieldCumulative valueYieldCPO={CalCumulative(yieldAll, 'Yield')} />
        </h2>
      </Box>
      <Box
        borderRadius="lg"
        borderWidth="1px"
        padding="4"
        className="justify mb-4"
      >
        <h1 className="flex justify-between p-4 xl:text-xl">
          {'Biogas'}
          <Link to={'/summarize/biogasdetail'}>
            <Button colorScheme="blue" size="sm" type="button">
              ดูรายละเอียดเพิ่มเติม
            </Button>
          </Link>
        </h1>
        <h1 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/biogasdetail'}>
            <LinkUI>
              <i className="fas fa-bolt"></i> {' ปริมาณหน่วยไฟฟ้าสะสม: '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {biogasAll?.length === 0
                  ? Intl.NumberFormat('en-US').format(
                      totalElectric?.numericStatus.toFixed(2)
                    )
                  : Intl.NumberFormat('en-US').format(
                      CalAVG(biogasAll, 'Total 1,2').toFixed(2)
                    )}
              </span>
              {' หน่วย/วัน'}
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {biogasAll?.length === 0
                  ? dayjs(totalElectric?.lastDate)
                      .locale('th')
                      .format('DD MMM BBBB')
                  : dayjs(startTime).locale('th').format('DD MMM BBBB') +
                    ' ' +
                    'ถึง' +
                    ' ' +
                    dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
          <ElectricalUnit valueTotalElectric={valueElectric} />
        </h1>{' '}
        <h2 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/biogasdetail'}>
            <LinkUI>
              <i className="fas fa-burn"></i> {'ปริมาณแก๊สที่ใช้ผลิตไฟฟ้า: '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {biogasAll?.length === 0
                  ? Intl.NumberFormat('en-US').format(
                      Number(parseFloat(gasUsed?.numericStatus)).toFixed(2)
                    ) || 0
                  : Intl.NumberFormat('en-US').format(
                      CalAVG(biogasAll, 'มิเตอร์ก๊าซที่ใช้ผลิต').toFixed(2)
                    ) || 0}
              </span>
              {' Nm'}
              <sup>{'3'}</sup>
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {biogasAll?.length === 0
                  ? dayjs(gasUsed?.lastDate).locale('th').format('DD MMM BBBB')
                  : dayjs(startTime).locale('th').format('DD MMM BBBB') +
                    ' ' +
                    'ถึง' +
                    ' ' +
                    dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
        </h2>
        <GasgenUnit valueGasUsed={valueGas} />
      </Box>
      {/* Biomass  */}
      <Box
        borderRadius="lg"
        borderWidth="1px"
        padding="4"
        className="justify mb-4"
      >
        <h1 className="flex justify-between p-4 xl:text-xl">
          {'Biomass'}
          <Link to={'/summarize/biomassdetail'}>
            <Button colorScheme="blue" size="sm" type="button">
              ดูรายละเอียดเพิ่มเติม
            </Button>
          </Link>
        </h1>

        <h1 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/biomassdetail'}>
            <LinkUI>
              <i className="fas fa-smog"></i> {' ปริมาณไอน้ำที่ผลิตได้: '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {Intl.NumberFormat('en-US').format(
                  parseFloat(CalAVG(findSumSteam, 'SumSteam')).toFixed(2)
                )}
              </span>
              {' ton/d'}
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {dayjs(startTime).locale('th').format('DD MMM BBBB') +
                  ' ' +
                  'ถึง' +
                  ' ' +
                  dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
        </h1>
        <SteamUnit valueSumSteam={valueFindSumSteam} />
        <h2 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/biomassdetail'}>
            <LinkUI>
              <DataUsageIcon /> {' ปริมาณเชื้อเพลิงที่ใช้: '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {AllFuel?.length === 0
                  ? Intl.NumberFormat('en-US').format(
                      Number(fuelUsed?.numericStatus.toFixed(2)).toFixed(2)
                    )
                  : Intl.NumberFormat('en-US').format(
                      CalAVG(AllFuel, 'QuantityPalm').toFixed(2)
                    )}
              </span>
              {' ton/d'}
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {AllFuel?.length === 0
                  ? dayjs(fuelUsed?.lastDate).locale('th').format('DD MMM BBBB')
                  : dayjs(startTime).locale('th').format('DD MMM BBBB') +
                    ' ' +
                    'ถึง' +
                    ' ' +
                    dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
        </h2>

        <FuelUnit valueFuelUsed={valueFuel} />
        <h3 className="justify-around sm:text-sm xl:text-xl font-bold font-sans">
          {' '}
          <Link to={'/summarize/biomassdetail'}>
            <LinkUI>
              <i className="fas fa-bolt"></i> {' ปริมาณไฟฟ้าที่ผลิตได้: '}{' '}
              <span className="sm:text-lg xl:text-2xl font-bold font-sans  text-blue-500 mb-0">
                {electricGenForm.length === 0
                  ? Intl.NumberFormat('en-US').format(
                      Number(electricGen?.numericStatus.toFixed(2)).toFixed(2)
                    ) || 0
                  : Intl.NumberFormat('en-US').format(
                      CalAVG(electricGenForm, 'ProductE').toFixed(2)
                    ) || 0}
              </span>
              {' KWh/day'}
              <span className="text-sm font-sans  text-gray-600 ml-10">
                {' ข้อมูลล่าสุดเมื่อวันที่ : '}
              </span>
              <span className="text-sm font-bold font-sans  text-gray-800">
                {electricGenForm.length === 0
                  ? dayjs(electricGen?.lastDate)
                      .locale('th')
                      .format('DD MMM BBBB')
                  : dayjs(startTime).locale('th').format('DD MMM BBBB') +
                    ' ' +
                    'ถึง' +
                    ' ' +
                    dayjs(endTime).locale('th').format('DD MMM BBBB')}
              </span>
            </LinkUI>
          </Link>
        </h3>
        <ElecUnitBiomass valueElectricGen={valueGenElectric} />
      </Box>
    </div>
  )
}
