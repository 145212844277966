/* eslint-disable no-unreachable */
/* eslint-disable import/extensions */
import React, { useEffect } from 'react'
import { Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'redux/actions'
import { useParams } from 'react-router-dom'

export default function HumenInputType({ stationData, register }) {
  const { t, i18n } = useTranslation()
  const form = useSelector((state) => state.form)
  const dispatch = useDispatch()

  useEffect(() => {
    if (stationData?._id) {
      dispatch(actions.formAll({ station: stationData?._id }))
    }
    return () => {}
  }, [])

  console.log('station', stationData)

  console.log('Form', form)
  const select = []

  _.map(form?.arr, (row, index) => {
    _.map(row.components, (comp, i) => {
      if (comp?.formType === 'MULTIPLE_INPUT') {
        _.map(comp?.attribute, (eachAttribute) => {
          select.push({
            value: `${row?._id}/${comp.name}.${eachAttribute.subTopic}/${row.name}:${comp.topic}-${eachAttribute?.subTopic}`,
            label:
              row?.name + ' : ' + comp.topic + ' - ' + eachAttribute?.subTopic,
          })
        })
      } else {
        select.push({
          value: `${row?._id}/${comp.name}/${row.name}-${comp.topic}`,
          label: `${row?.name} - ${comp.topic}`,
        })
      }
    })
    _.map(row.subforms, (subForm, i) => {
      _.map(subForm.components, (subComp, ind) => {
        if (subComp?.formType === 'MULTIPLE_INPUT') {
          _.map(subComp?.attribute, (subAttribute) => {
            select.push({
              value: `${subForm?.form_id}/${subComp.name}.${subAttribute.subTopic}/${subForm?._id}`,
              label:
                subForm?.name +
                ' : ' +
                subComp.topic +
                ' - ' +
                subAttribute?.subTopic,
            })
          })
        } else {
          select.push({
            value: `${subForm?.form_id}/${subComp.name}/${subForm?._id}`,
            label: `${subForm?.name} - ${subComp.topic}`,
          })
        }
      })
    })
  })
  //console.log('select', select)
  return _.map(select, (each) => (
    <option value={each?.value}>{each?.label}</option>
  ))
}
