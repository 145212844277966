import React from 'react'
import { Box } from '@chakra-ui/react'
import staticNumberWarningCheck from '../../../../config/staticNumberWarningCheck'
import { WarningColorClass } from '../../../../config/ComponentType'
import { Link } from 'react-router-dom'
import AlarmBox from '../AlarmBox'

export default function StaticNumberGauge({
  staticData,
  unit = '',
  graphLabel = '',
  favoriteButton,
  selectedSensor,
  useThresholdAlarm = false,
  link = null,
  size = 'md', // md or sm,
}) {
  const fixData = (data) => {
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(data)
  }

  const warningStatus = staticNumberWarningCheck({
    controlRange: selectedSensor?.controlRange,
    threshold: selectedSensor?.threshold,
    sensorNumberStatus: staticData,
  })

  const sizeSelector = {
    md: {
      mainData: 'text-4xl',
      label: 'text-2xl',
      weight: 'font-bold',
      unit: 'text-xl',
    },
    sm: {
      mainData: 'text-xl',
      label: 'text-normal',
      weight: 'font-bold',
      unit: 'text-normal',
    },
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-2 w-full  md:w-full  h-25"
    >
      <div className="flex flex-row">
        {favoriteButton}
        {link ? (
          <Link
            to={link}
            className={`${sizeSelector[size]?.weight || 'font-bold'}  
          ${sizeSelector[size]?.label || 'text-2xl'}  ${
              favoriteButton ? 'px-2' : ''
            } hover:text-gray-600`}
          >
            {graphLabel}
          </Link>
        ) : (
          <h5
            className={`${sizeSelector[size]?.weight || 'font-bold'}  
          ${sizeSelector[size]?.label || 'text-2xl'}  ${
              favoriteButton ? 'px-2' : ''
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: graphLabel }}></div>
          </h5>
        )}
      </div>
      <div>
        <div className={`${size === 'sm' ? 'flex gap-2 items-end' : ''} `}>
          <h1
            className={`${sizeSelector[size]?.weight || 'font-bold'} ${
              sizeSelector[size]?.mainData || 'text-4xl'
            } ${
              staticData === null ? 'text-yellow-500' : 'text-blue-500'
            } mb-0`}
          >
            {staticData === null ? (
              0
            ) : selectedSensor?.error ? (
              <span
                className={`text-red-500  ${
                  sizeSelector[size]?.label || 'text-2xl'
                } `}
              >
                {selectedSensor?.errorMessage}
              </span>
            ) : (
              <div>
                {useThresholdAlarm ? (
                  <div className={WarningColorClass[warningStatus] || ''}>
                    {fixData(staticData?.toFixed(2))}
                  </div>
                ) : (
                  fixData(staticData?.toFixed(2))
                )}
              </div>
            )}
          </h1>{' '}
          <h3
            className={`font-semibold  ${
              sizeSelector[size]?.unit || 'text-xl'
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: unit }}></div>
          </h3>
        </div>
        {useThresholdAlarm && (
          <AlarmBox
            controlRange={selectedSensor?.controlRange}
            warningStatus={warningStatus}
            unit={unit}
            staticData={staticData}
          />
        )}
      </div>
    </Box>
  )
}
