import React from 'react'
import {
  Button,
  Link as LinkUI,
  Box,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp'

export default function DisplayValueAlert({
  oilLossAll,
  yieldAll,
  valueLoss,
  oilLoss,
  name,
}) {
  if (name === 'ปริมาณการสูญเสียน้ำมันจากเส้นใย') {
    if (
      oilLossAll[0]?.length !== 0 &&
      (parseFloat(oilLossAll[0]?.OilLossFiber) * 100) /
        (parseFloat(yieldAll[0]?.PalmUsed) * 0.133) <
        4.5
    ) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันจากเส้นใย'}</StatLabel>
          <StatNumber textColor="blue.400">
            {parseFloat(oilLossAll[0]?.OilLossFiber).toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatHelpText>
            {dayjs(oilLossAll[0]?.date).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (
      oilLossAll[0]?.length === 0 &&
      (valueLoss?.numericStatus * 100) / (ffb * 0.133) < 4.5
    ) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันจากเส้นใย'}</StatLabel>
          <StatNumber textColor="blue.400">
            {valueLoss?.numericStatus.toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatHelpText>
            {dayjs(valueLoss?.lastDate).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (oilLossAll[0]?.length !== 0) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันจากเส้นใย'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {parseFloat(oilLossAll[0]?.OilLossFiber).toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatLabel>
            <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
            {(
              ((parseFloat(oilLossAll[0]?.OilLossFiber) * 100) /
                (parseFloat(yieldAll[0]?.PalmUsed) * 0.133) -
                4.5) *
              (parseFloat(yieldAll[0]?.PalmUsed) / 100) *
              0.133
            ).toFixed(2)}
            {' ton/d'}
          </StatLabel>
          <StatHelpText>
            {dayjs(oilLossAll[0]?.date).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (oilLossAll[0]?.length === 0) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันจากเส้นใย'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {valueLoss?.numericStatus.toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatLabel>
            <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
            {(
              ((valueLoss?.numericStatus * 100) / (ffb * 0.133) - 4.5) *
              (ffb / 100) *
              0.133
            ).toFixed(2)}
            {' ton/d'}
          </StatLabel>
          <StatHelpText>
            {dayjs(valueLoss?.lastDate).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันจากเส้นใย'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {'N/A'} {' ton/d'}
          </StatNumber>
        </Stat>
      )
    }
  } else if (name === 'การสูญเสียน้ำมันใน DECANTER CAKE') {
    if (
      oilLossAll[0]?.length !== 0 &&
      (parseFloat(oilLossAll[0]?.OilLossDecanter) * 100) /
        (parseFloat(yieldAll[0]?.PalmUsed) * 0.035) <
        3.5
    ) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันใน DECANTER CAKE'}</StatLabel>
          <StatNumber textColor="blue.400">
            {parseFloat(oilLossAll[0]?.OilLossDecanter).toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatHelpText>
            {dayjs(oilLossAll[0]?.date).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (
      oilLossAll[0]?.length === 0 &&
      (valueLoss?.numericStatus * 100) / (ffb * 0.035) < 3.5
    ) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันใน DECANTER CAKE'}</StatLabel>
          <StatNumber textColor="blue.400">
            {valueLoss?.numericStatus.toFixed(2)} {' ton/d'}
          </StatNumber>

          <StatHelpText>
            {dayjs(valueLoss?.lastDate).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (oilLossAll[0]?.length !== 0) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันใน DECANTER CAKE'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {parseFloat(oilLossAll[0]?.OilLossDecanter).toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatLabel>
            <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
            {((parseFloat(oilLossAll[0]?.OilLossDecanter) * 100) /
              (parseFloat(yieldAll[0]?.PalmUsed) * 0.035) -
              3.5) *
              (parseFloat(yieldAll[0]?.PalmUsed) / 100) *
              (0.35).toFixed(2)}{' '}
            {' ton/d'}
          </StatLabel>
          <StatHelpText>
            {dayjs(oilLossAll[0]?.date).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (oilLossAll[0]?.length === 0) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันใน DECANTER CAKE'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {valueLoss?.numericStatus.toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatLabel>
            <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
            {(
              ((valueLoss?.numericStatus * 100) / (ffb * 0.035) - 3.5) *
              (ffb / 100) *
              0.035
            ).toFixed(2)}
            {' ton/d'}
          </StatLabel>
          <StatHelpText>
            {dayjs(valueLoss?.lastDate).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันใน DECANTER CAKE'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {'N/A'} {' ton/d'}
          </StatNumber>
        </Stat>
      )
    }
  } else if (name === 'การสูญเสียน้ำมันในน้ำเสีย') {
    if (
      oilLossAll[0]?.length !== 0 &&
      (parseFloat(oilLossAll[0]?.OilLossWastewater) * 100) /
        (parseFloat(yieldAll[0]?.PalmUsed) * 0.5) <
        1
    ) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันในน้ำเสีย'}</StatLabel>
          <StatNumber textColor="blue.400">
            {parseFloat(oilLossAll[0]?.OilLossWastewater).toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatHelpText>
            {dayjs(oilLossAll[0]?.date).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (
      oilLossAll[0]?.length === 0 &&
      (valueLoss?.numericStatus * 100) / (ffb * 0.5) < 1
    ) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันในน้ำเสีย'}</StatLabel>
          <StatNumber textColor="blue.400">
            {valueLoss?.numericStatus.toFixed(2)} {' ton/d'}
          </StatNumber>

          <StatHelpText>
            {dayjs(valueLoss?.lastDate).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (oilLossAll[0]?.length !== 0) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันในน้ำเสีย'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {parseFloat(oilLossAll[0]?.OilLossWastewater).toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatLabel>
            <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
            {(
              ((parseFloat(oilLossAll[0]?.OilLossWastewater) * 100) /
                (parseFloat(yieldAll[0]?.PalmUsed) * 0.5) -
                1) *
              (parseFloat(yieldAll[0]?.PalmUsed) / 100) *
              0.5
            ).toFixed(2)}
            {' ton/d'}
          </StatLabel>
          <StatHelpText>
            {dayjs(oilLossAll[0]?.date).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else if (oilLossAll[0]?.length === 0) {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันในน้ำเสีย'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {valueLoss?.numericStatus.toFixed(2)} {' ton/d'}
          </StatNumber>
          <StatLabel>
            <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
            {(
              ((valueLoss?.numericStatus * 100) / (ffb * 0.5) - 1) *
              (ffb / 100) *
              0.5
            ).toFixed(2)}
            {' ton/d'}
          </StatLabel>
          <StatHelpText>
            {dayjs(valueLoss?.lastDate).locale('th').format('DD MMM BBBB')}
          </StatHelpText>
        </Stat>
      )
    } else {
      return (
        <Stat>
          <StatLabel>{'การสูญเสียน้ำมันในน้ำเสีย'}</StatLabel>
          <StatNumber textColor="yellow.400">
            {'N/A'} {' ton/d'}
          </StatNumber>
        </Stat>
      )
    }
  } else if (name === 'ปริมาณการสูญเสียน้ำมันทั้งหมด') {
    if (
      oilLossAll[0]?.length !== 0 &&
      (parseFloat(oilLossAll[0]?.OilLossFiber) * 100) /
        (parseFloat(yieldAll[0]?.PalmUsed) * 0.133) +
        (parseFloat(oilLossAll?.OilLossDecanter) * 100) /
          (parseFloat(yieldAll[0]?.PalmUsed) * 0.035) +
        (parseFloat(oilLossAll[0]?.OilLossWastewater) * 100) /
          (parseFloat(yieldAll[0]?.PalmUsed) * 0.5) <
        5
    ) {
      return (
        <StatNumber textColor="blue.400" className="flex justify-center ">
          {(
            parseFloat(oilLossAll[0]?.OilLossFiber) +
            parseFloat(oilLossAll[0]?.OilLossDecanter) +
            parseFloat(oilLossAll[0]?.OilLossWastewater)
          ).toFixed(2)}
        </StatNumber>
      )
    } else if (oilLossAll[0]?.length === 0 && valueLoss < 5) {
      return (
        <StatNumber textColor="blue.400" className="flex justify-center ">
          {oilLoss?.numericStatus.toFixed(2)}
        </StatNumber>
      )
    } else if (oilLossAll[0]?.length !== 0) {
      return (
        <Stat>
          <StatNumber textColor="yellow.400" className="flex justify-center ">
            {(
              parseFloat(oilLossAll[0]?.OilLossFiber) +
              parseFloat(oilLossAll[0]?.OilLossDecanter) +
              parseFloat(oilLossAll[0]?.OilLossWastewater)
            ).toFixed(2)}
          </StatNumber>
          <StatLabel className="flex justify-center">
            <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
            {(
              (parseFloat(oilLossAll[0]?.OilLossDecanter) * 2857.142857) /
                parseFloat(yieldAll[0]?.PalmUsed) +
              (parseFloat(oilLossAll[0]?.OilLossFiber) * 751.879699) /
                parseFloat(yieldAll[0]?.PalmUsed) +
              (parseFloat(oilLossAll[0]?.OilLossWastewater) * 200) /
                parseFloat(yieldAll[0]?.PalmUsed) -
              5
            ).toFixed(2)}
          </StatLabel>
        </Stat>
      )
    } else if (oilLossAll[0]?.length === 0) {
      return (
        <Stat>
          <StatNumber textColor="yellow.400" className="flex justify-center">
            {oilLoss?.numericStatus.toFixed(2)}
          </StatNumber>
          <StatLabel className="flex justify-center">
            <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
            {valueLoss - 5}
          </StatLabel>
        </Stat>
      )
    } else {
      return <StatNumber textColor="yellow.400">{'N/A'}</StatNumber>
    }
  }
}
