import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _, { merge } from 'lodash'
import { Button, Box, Switch } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
//import PredictionList from '../management-prediction/Component/PredictionList'
import { useParams, useHistory, Link } from 'react-router-dom'
import * as actions from '../../../redux/actions'

export default function ManagementPrediction() {
  const { t } = useTranslation()
  const { register, handleSubmit, reset } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => {})
    return () => {}
  }, [])

  const findStation = (id) => {
    const data = _?.find(allStations?.arr, (each) => each?._id === id)
    return data
  }

  const handleChangeSwitch = async (value, station, sensor) => {
    const tempSensor = _?.find(station?.sensors, (each) => each?._id === sensor)
    const sensorIndex = _.findIndex(station?.sensors, (ss) => ss._id === sensor)
    const payload = { ...tempSensor, predictionEnable: value }
    const mergeSensorData = _.merge(tempSensor, payload)
    const editedStation = station
    editedStation.sensors[sensorIndex] = mergeSensorData
    await dispatch(actions.stationPut(station._id, editedStation))
    //console.log('payload', editedStation)
  }

  const genPredictionList = (station) => {
    return _.map(station?.sensors, (sensor, index) => {
      return (
        <Box
          padding="4"
          borderRadius="lg"
          borderWidth="1px"
          className="mb-2"
          key={station._id}
        >
          <div className="flex flex-row">
            <div className="flex flex-col w-4/5 m-2 p-1">
              <h3 className="font-bold text-xl font-sans">
                <i className="fas fa-search text-sm"> </i> {sensor?.name}{' '}
              </h3>

              <h5 className="text-base text-gray-700 font-sans">
                {' '}
                {station.name}
              </h5>
            </div>
            <div className="flex flex-col items-center self-center w-1/5">
              <h4 className="text-base text-gray-700 font-sans">
                {' '}
                เปิด/ปิด การทำนาย
              </h4>
              <Switch
                size="lg"
                defaultChecked={sensor?.predictionEnable}
                onChange={(e) => {
                  handleChangeSwitch(e.target.checked, station, sensor._id)
                }}
              />
            </div>
          </div>
        </Box>
      )
    })
  }

  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                {t('setting.systemManagement')}
              </h6>
              <h2 className="text-gray-800 text-2xl font-bold font-sans">
                จัดการรูปแบบการทำนาย
              </h2>
            </div>
          </div>
        </div>

        {_.map(allStations?.arr, (station, index) => (
          <div className="p-4 w-full">
            {' '}
            <h3 className="text-gray-800 text-2xl font-bold font-sans p-4">
              {'เซนเซอร์ใน '}
              {station.name}{' '}
            </h3>{' '}
            {genPredictionList(findStation(station?._id))}
          </div>
        ))}
      </div>
    </div>
  )
}
