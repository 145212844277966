import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'
import GraphPrediction from '../prediction/component/graphPrediction'

import { Button, Link as LinkUI, Box } from '@chakra-ui/react'

import { useParams, useHistory } from 'react-router-dom'

export default function OneSensorGraphPrediction() {
  const dispatch = useDispatch()
  const params = useParams()
  const summarize = useSelector((state) => state.summarize)
  const stations = useSelector((state) => state.station)
  const predictions = useSelector((state) => state.prediction)
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const allLogs = useSelector((state) => state.log)
  const history = useHistory()
  useEffect(() => {
    dispatch(actions.summarizeGet(params.summarizeId))
    return () => {}
  }, [])

  useEffect(() => {
    if (summarize?.station) {
      if (params.summarizeId && isFetchNewOne === true) {
        if (isFirstTime === true) {
          dispatch(actions.logLastestTwenty(summarize?.station))
            .then(() => {
              setisFetchNewOne(false)
              setIsFirstTime(false)
              setIsLoading(true)
            })
            .catch((err) => {
              alert('Error on Fetching log')
            })

          dispatch(
            actions.predictionVarDetail({
              summarizedId: params.summarizeId,
              varDetailId: params.id,
            })
          )
          dispatch(actions.stationAll())
        } else {
          //setTickAmount(10)
          dispatch(actions.logLastestTwenty(summarize?.station))
          dispatch(
            actions.predictionVarDetail({
              summarizedId: params.summarizeId,
              varDetailId: params.id,
            })
          )
          setisFetchNewOne(false)
        }
      }
    }

    return () => {}
  }, [isFetchNewOne, params.summarizeId, summarize])

  const foundStation = (id) => {
    return _.find(stations?.arr, (each) => {
      return each?._id === id
    })
  }

  const findSensor = (id) => {
    let data = {}
    _.map(stations?.arr, (eachStation) => {
      _.map(eachStation.sensors, (ss) => {
        if (ss?._id === id) {
          data = ss
        }
      })
    })

    return data
  }

  const findPrediction = (id) => {
    return _.find(predictions?.arr, (each) => each?.sourceId === id)
  }

  // useEffect(() => {
  //   let timeout
  //   if (predictions?.arr) {
  //     setIsLoading(false)
  //     timeout = setTimeout(() => {
  //       setIsLoading(true)
  //     }, 500)
  //   }

  //   return () => {
  //     if (timeout) {
  //       clearTimeout(timeout)
  //     }
  //   }
  // }, [predictions])

  const station = foundStation(summarize?.station)

  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded ">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                การสรุปผลการดำเนินงาน
              </h6>
              <h2 className="text-gray-800 text-2xl font-bold font-sans">
                {`เซนเซอร์ ${findSensor(params.sensorId)?.name}`}
              </h2>
            </div>
          </div>

          <div className="flex justify-between my-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                history.goBack()
              }}
            >
              กลับ
            </Button>
          </div>
          <div className="p-4">
            <GraphPrediction
              chartData={findSensor(params.sensorId)}
              data={allLogs}
              dataPrediction={findPrediction(params.sensorId)}
              // graphMax={graphMax}
              // graphMin={graphMin}
              // setGraphMax={setGraphMax}
              // setGraphMin={setGraphMin}
              // setTickAmount={setTickAmount}
              // setValueFormat={setValueFormat}
              // tickAmount={tickAmount}
              isLoading={isLoading}
              //isSuccess={isSuccess}
              stationData={station}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
