import _ from 'lodash'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { count } from 'mathjs'

dayjs.extend(duration)

export default function FetchDataQC(dataArr, sensorId) {
  let tempValueStack = []
  let nowDate = dayjs()
  const prevday = dayjs(nowDate).subtract(1, 'month')
  let indexDate = nowDate.diff(prevday, 'day')

  _.map(dataArr, (dataItem, index) => {
    while (
      dayjs(dataItem?.date).format('YYYY-MM-DD') !==
        dayjs(nowDate).format('YYYY-MM-DD') &&
      indexDate >= 0
    ) {
      tempValueStack.push({
        x: dayjs(nowDate).format('YYYY-MM-DD'),
        y: 0,
      })
      indexDate -= 1
      nowDate = dayjs(nowDate).subtract(1, 'day').format('YYYY-MM-DD')
    }
    indexDate -= 1
    nowDate = dayjs(dataItem?.date).subtract(1, 'day').format('YYYY-MM-DD')

    _.map(dataItem?.value, (sensor) => {
      if (sensor?.variableId === sensorId) {
        //console.log('sensorrr', sensor?.value)
        //console.log('XAxis Data', dataItem?.date)
        //console.log('YAXis Data', dataItem?.value)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y: sensor?.value === null ? 0 : parseFloat(sensor?.value).toFixed(2),
        })
      }
    })
  })

  // console.log('TEMP Fetch ', tempValueStack)
  return tempValueStack
}
