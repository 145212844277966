import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
import SummarizeComponent from './Component/SummarizeComponent'
import { useParams, useHistory, Link } from 'react-router-dom'
import * as actions from '../../../redux/actions'

export default function SummarizeConfig() {
  const { t } = useTranslation()
  const { register, handleSubmit, reset } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const summarizes = useSelector((state) => state.summarize)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.summarizeAll()).then(() => {})
    dispatch(actions.stationAll()).then(() => {})
    return () => {}
  }, [])

  const findStation = (id) => {
    const data = _?.find(allStations?.arr, (each) => each?._id === id)
    return data
  }

  const genSummarizeList = () => {
    return _.map(summarizes?.arr, (summarize, index) => {
      return (
        <div>
          <SummarizeComponent
            key={index}
            summarize={summarize}
            stations={findStation(summarize.station)}
            allStation={allStations}
          />
        </div>
      )
    })
  }

  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                {t('setting.systemManagement')}
              </h6>
              <h2 className="text-gray-800 text-2xl font-bold font-sans">
                รูปแบบสรุป
              </h2>
            </div>
          </div>
        </div>
        <div className="flex justify-start gap-1 p-4">
          <Link to={'/system/summarizeconfig/addexpression'}>
            <Button colorScheme="messenger" variant="solid" size="sm">
              เพิ่มนิพจน์
            </Button>
          </Link>
          <Link to={'/system/summarizeconfig/addsubexpression'}>
            <Button colorScheme="twitter" variant="solid" size="sm">
              เพิ่มพจน์
            </Button>
          </Link>
        </div>
        <div className="p-4 w-full">{genSummarizeList()}</div>
      </div>
    </div>
  )
}
