/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { Button, Box, Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import { stat } from 'fs'

export default function FetchPrediction({
  register,
  append,
  remove,
  fields,
  station,
  control,
  setValue,
  watch,
}) {
  // const prediction = useSelector((state) => state.prediction)

  const { t, i18n } = useTranslation()
  const handleRemove = (index) => {
    remove(index)
  }

  const findData = (id) => {
    console.log('id ', id)
    const selectID = id.split('/')
    const data = {
      gropIndex: 0,
      station: selectID[0],
      sourceId: selectID[1],
      sourceName: selectID[2],
    }
    //const data = _?.find(prediction?.arr, (each) => each?._id === id)
    console.log('selectvariable', data)
    //console.log('station?.variable', station?.variables)
    // setSelectVariable(data)

    return data
  }

  //console.log('stataion ', station)
  const sensorMap = []
  _.map(station?.arr, (st, key) => {
    return _.map(st.sensors, (ss) => {
      if (ss?.predictionEnable) {
        sensorMap.push({
          id: ss?._id,
          name: ss?.name,
          station: st?._id,
        })
      }
    })
  })
  //console.log('sensor', sensorMap)

  // const selectData = () => {
  //   return _.map(station?.arr, (st, key) => {
  //     return _.map(st.sensors, (ss) => {
  //       if (ss?.predictionEnable) {
  //         return (
  //           <option value={st._id + '/' + ss?._id + '/' + ss?.name}>
  //             {ss?.name}
  //           </option>
  //         )
  //       }
  //     })
  //   })
  // }

  return (
    <div className="w-full  px-4">
      <div>
        <div className="w-full  px-4">
          {console.log('fields', fields)}
          {_.map(fields, (row, index) => (
            <Box
              key={row?.id}
              padding="4"
              borderRadius="lg"
              borderWidth="2"
              className="text-sm font-semibold border mb-2"
            >
              <div className="flex justify-between">
                <h5 className="mb-2">เซนเซอร์ที่ {index + 1} </h5>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => handleRemove(index)}
                >
                  {t('utility.delete')}
                </Button>
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  เลือกข้อมูลเซนเซอร์
                </label>
                <Controller
                  name={`varDetail[0].attribute[${index}].sourceId`}
                  defaultValue={row?.attribute?.[index]?.sourceId}
                  control={control}
                  render={(field) => (
                    <Select
                      {...field}
                      size="sm"
                      placeholder="เลือกข้อมูล"
                      onChange={(e) => {
                        const foundSensorId = e.target.value
                        const foundSensor = _.find(
                          sensorMap,
                          (each) => each?.id === foundSensorId
                        )
                        setValue(`varDetail[0].attribute[${index}]`, {
                          sourceName: foundSensor?.name,
                          station: foundSensor?.station,
                          sourceId: foundSensor?.id,
                          groupIndex: 0,
                        })
                      }}
                    >
                      {/* ใช้ตัวนี้เป็นการรับ Source ว่าเป็น Sensor หรือ Varible หรือ การกรอกข้อมูล */}
                      {_.map(sensorMap, (ss, ssIndex) => (
                        <option value={ss?.id} key={ssIndex}>
                          {ss?.name}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                <Controller
                  name={`varDetail[0].attribute[${index}].station`}
                  defaultValue={row?.attribute?.[index]?.station}
                  control={control}
                  render={() => <div></div>}
                />

                <Controller
                  name={`varDetail[0].attribute[${index}].groupIndex`}
                  control={control}
                  defaultValue={row?.attribute?.[index]?.groupIndex}
                  render={() => <div></div>}
                />

                <Controller
                  name={`varDetail[0].attribute[${index}].sourceName`}
                  defaultValue={row?.attribute?.[index]?.sourceName}
                  control={control}
                  render={() => <div></div>}
                />
              </div>
            </Box>
          ))}
          <Button
            isFullWidth
            colorScheme="blue"
            size="sm"
            onClick={() => append({})}
          >
            {t('utility.addNewList')}
          </Button>
        </div>
      </div>
    </div>
  )
}
