import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _, { each } from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderMapTime = (data, timeMap) => {
  const content = []
  // const DataSet = renderCheckContRows(data)
  _.map(timeMap, (time, index) => {
    let indexTime = false

    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (time === eachData?.Time) {
          indexTime = true
          console.log('Peak', eachData.Peak)
          content.push([
            { text: eachData?.Time, style: 'tableContent' },
            {
              text: eachData?.PF,
              style: 'tableContent',
            },
            {
              text: eachData['1AH5'],
              style: 'tableContent',
            },
            {
              text: eachData?.NeatralN,
              style: 'tableContent',
            },
            {
              text: eachData?.NeatralP,
              style: 'tableContent',
            },
            {
              text: eachData?.Temp,
              style: 'tableContent',
            },
            {
              text: eachData?.Power_Not,
              style: 'tableContent',
            },
            {
              text: eachData?.Voltage,
              style: 'tableContent',
            },
            {
              text: eachData?.Pressure,
              style: 'tableContent',
            },
            {
              text: eachData?.TempC,
              style: 'tableContent',
            },
            {
              text: eachData?.Flow,
              style: 'tableContent',
            },
            {
              text: eachData?.comment,
              style: 'tableContent',
            },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const FM_BM_001 = async (form, formInput, subForm, system, date) => {
  const timeMap = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '24:00',
  ]
  const DataFooter = _.filter(formInput?.arr, {
    subForm_name: 'สรุป Electricity',
  })
  console.log('DataFooter', DataFooter)

  const TableBody = renderMapTime(formInput.arr, timeMap)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [10, 120, 10, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header(currentPage, pageCount, pageSize) {
      return [
        {
          margin: [20, 30, 20, 10],
          table: {
            widths: ['23%', '54%', '12%', '11%'],
            body: [
              [
                {
                  image: 'logo_imge',
                  rowSpan: 2,
                  fit: [35, 35],
                  alignment: 'center',
                  margin: [0, 2, 0, 0],
                  border: [true, true, true, false],
                },
                {
                  text: `${form?.name || ''} - (FM-BM-001)`,
                  alignment: 'center',
                  bold: true,
                  rowSpan: 3,
                  fontSize: 20,
                  margin: [0, 10],
                },
                {
                  text: `รหัสเอกสาร:  ${form?.docNo || '-'}  `,
                  style: 'header',
                  border: [true, true, true, false],
                  colSpan: 2,
                },
                {},
              ],
              [
                {},
                { text: '', alignment: 'center' },
                {
                  text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                  style: 'header',
                  border: [true, true, true, true],
                },
                {
                  text: 'หน้าที่ : ' + currentPage + '/' + pageCount,
                  style: 'header',
                  border: [true, true, true, true],
                },
              ],
              [
                {
                  text: 'THAI TALLOW AND OIL CO.,LTD.',
                  alignment: 'center',
                  bold: true,
                  font: 'Impac',
                  fontSize: 12,
                  border: [true, false, true, true],
                },
                {},
                {
                  text: `วันที่ประกาศใช้ : ${
                    dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                  }`,
                  style: 'header',
                  border: [true, true, true, true],
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },
      ]
    },
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        text: `วันที่ ${dayjs(date).format('D เดือน MMMM พ.ศ. BBBB ')}`,
        alignment: 'right',
        style: 'headerTable',
        fontSize: '14',
        margin: [10, 0, 10, 0],
      },
      {
        margin: [10, 5, 10, 10],
        alignment: 'between',
        table: {
          headerRows: 3,
          widths: [
            '8.3%',
            '8.3%',
            '8.3%',
            '8.3%',
            '8.3%',
            '8.3%',
            '8.3%',
            '8.3%',
            '8.3%',
            '8.3%',
            '8.3%',
            '8.7%',
          ],
          body: [
            [
              {
                text: 'Time',
                rowSpan: 2,
                style: 'headerTable',
              },
              {
                text: 'PF',
                rowSpan: 2,
                style: 'headerTable',
              },
              {
                text: '1AH5',
                rowSpan: 2,
                style: 'headerTable',
              },
              {
                text: 'Neatral',
                rowSpan: 2,
                style: 'headerTable',
              },
              {
                text: 'Netral',
                rowSpan: 2,
                style: 'headerTable',
              },
              {
                text: 'Temp',
                rowSpan: 2,
                style: 'headerTable',
              },
              {
                text: 'Power Not',
                rowSpan: 2,
                style: 'headerTable',
              },
              {
                text: 'Voltag',
                border: [true, true, true, false],
                style: 'headerTable',
              },
              {
                text: 'แรงดัน ',
                border: [true, true, true, false],
                style: 'headerTable',
              },
              {
                text: 'อุณหภูมิ ',
                border: [true, true, true, false],
                style: 'headerTable',
              },
              {
                text: 'Flow ',
                border: [true, true, true, false],
                style: 'headerTable',
              },
              {
                text: '',
                border: [true, true, true, false],
                style: 'headerTable',
              },
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: '(V)',
                border: [true, false, true, true],
                style: 'headerTable',
              },
              {
                text: '(Mpa)',
                border: [true, false, true, true],
                style: 'headerTable',
              },
              {
                text: '(C)',
                border: [true, false, true, true],
                style: 'headerTable',
              },
              {
                text: '(t/h)',
                border: [true, false, true, true],
                style: 'headerTable',
              },
              {
                text: 'หมายเหตุ',
                border: [true, false, true, false],
                style: 'headerTable',
              },
            ],
            [
              {
                text: 'REC',
                style: 'headerTable',
              },
              {
                text: '0.90-0.99',
                style: 'headerTable',
              },
              {
                text: '+kw/h',
                style: 'headerTable',
              },
              {
                text: '+kw/h',
                style: 'headerTable',
              },
              {
                text: '+kw/h',
                style: 'headerTable',
              },
              {
                text: '2TD',
                style: 'headerTable',
              },
              {
                text: 'Over 6750 kw',
                style: 'headerTable',
              },
              {
                text: '380-420',
                style: 'headerTable',
              },
              {
                text: '3.00-3.80',
                style: 'headerTable',
              },
              {
                text: '440-470',
                style: 'headerTable',
              },
              {
                text: '40-50',
                style: 'headerTable',
              },
              {
                text: '',
                border: [true, false, true, true],
              },
            ],
            ...TableBody,
          ],
        },
      },
      {
        margin: [10, 30],
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: `วันที่ ${dayjs(date).format('D เดือน MMMM พ.ศ. BBBB ')}`,
                alignment: 'left',
                margin: [50, 0, 110, 0],
                bold: true,
                border: [false, false, false, false],
              },

              {
                text: 'ผู้ตรวจสอบ',
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text:
                  DataFooter?.length === 1
                    ? 'Peak\t\t\t\t\t\t\t\t\t =\t\t\t' +
                      DataFooter[0]?.Peak +
                      '\t\t\tKwh.'
                    : 'Peak\t\t\t\t\t\t\t\t\t = ........Kwh.',
                alignment: 'left',
                bold: true,
                margin: [40, 0, 100, 0],
                border: [false, false, false, false],
              },
              {
                text: ' ........................................................',
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text:
                  DataFooter?.length === 1
                    ? 'Off Peak \t\t\t\t\t\t\t = \t\t\t' +
                      DataFooter[0]?.OffPeak +
                      '\t\t\tKwh.'
                    : 'Off Peak \t\t\t\t\t\t\t = ........Kwh.',
                alignment: 'left',
                bold: true,
                margin: [40, 0, 100, 0],
                border: [false, false, false, false],
              },
              {
                text: ' (........................................................)',
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text:
                  DataFooter?.length === 1
                    ? 'Total \t\t\t\t\t\t\t\t\t= \t\t\t' +
                      DataFooter[0]?.Total +
                      '\t\t\tKwh.'
                    : 'Total \t\t\t\t\t\t\t\t\t= ........Kwh.',
                alignment: 'left',
                bold: true,
                margin: [40, 0, 100, 0],
                border: [false, false, false, false],
              },
              {
                text: ' หัวหน้าแผนก Biomass',
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text:
                  DataFooter?.length === 1
                    ? 'กำลังการผลิต\t\t\t\t\t\t= \t\t\t' +
                      DataFooter[0]?.ProductE +
                      '\t\t\tKwh.'
                    : 'กำลังการผลิต\t\t\t\t\t\t= ........Kwh.',
                alignment: 'left',
                bold: true,
                margin: [40, 0, 100, 0],
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text:
                  DataFooter?.length === 1
                    ? 'จำนวนการเดินเครื่อง\t\t\t= \t\t\t' +
                      DataFooter[0]?.StartEngine +
                      '\t\t\tชั่วโมง.'
                    : 'จำนวนการเดินเครื่อง\t\t\t= ........ชั่วโมง.',
                alignment: 'left',
                bold: true,
                margin: [40, 0, 100, 0],
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],
    // footer: function (currentPage, pageCount) {
    //   return []
    // },

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 7,
      },
      header: {
        bold: true,
        fontSize: 14,
        margin: [0, 0, 0, 0],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [-3, 0, -3, 0],
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [0, 0, 0, 0],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BM_001
