import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import GeneralFormSelector from './GeneralFormSelector'
import _ from 'lodash'
import { Box, Button } from '@mui/material'
import { log10, typeOf } from 'mathjs'
import dayjs from 'dayjs'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import GeneralEditFormSelector from './GeneralEditFormSelector'

export default function GeneratEditForm({ form, formInput, handleEdit }) {
  const { register, control, setValue, watch, handleSubmit } = useForm({
    defaultValues: formInput,
  })
  //const [date, setDate] = useState(new Date())
  //console.log('date ', formInput)

  const renderForm = () =>
    _.map(form?.components, (formData, index) => (
      <div key={index}>
        <GeneralEditFormSelector
          formData={formData}
          watch={watch}
          formInput={formInput}
          register={register}
        />
      </div>
    ))

  return (
    <div>
      <form onSubmit={handleSubmit(handleEdit)}>
        <div className="md:grid lg:grid-cols-4 md:grid-cols-2">
          {/* <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            className=" ml-4 my-2 p-4 w-auto h-36"
          >
            <label className="block uppercase text-gray-700 text-md font-bold mb-2">
              วันที่บันทึก
            </label>
            <input
              name="date"
              type="date"
              ref={register}
              required
              defaultValue={formInput?.date}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
            />
          </Box> */}
          {renderForm()}
        </div>

        <div className="flex justify-center py-2 ">
          <Button type="submit" variant="contained">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  )
}
