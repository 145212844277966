/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import { Badge, Box, Button } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import _, { each } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import 'katex/dist/katex.min.css'

import * as actions from '../../../../redux/actions'

export default function SummarizeComponent({
  summarize,
  stations,
  allStation,
}) {
  //console.log('Prediction', summarize)

  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)

  const dispatch = useDispatch()

  const prepateDeleteSummarize = (summarizeId) => {
    let confirm = window.confirm('ยืนยันการลบการสรุป')
    if (confirm) {
      dispatch(actions.summarizeDelete(summarizeId)).then(() => {
        dispatch(actions.summarizeAll())
      })

      setIsEditorMode(false)
    }
  }

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      <div className="flex gap-3">
        <div className="w-5/6">
          <h5 className="text-lg font-bold font-sans">
            {' '}
            {summarize?.variable?.variableName}{' '}
            <Badge colorScheme="teal" size="sm">
              {summarize?.expression === true ? 'นิพจน์' : 'พจน์'}{' '}
            </Badge>{' '}
          </h5>
        </div>

        <div className="flex gap-1 ">
          <Link
            to={
              summarize?.expression == true
                ? 'summarizeconfig/expression/edit/' + summarize?._id
                : 'summarizeconfig/subexpression/edit/' + summarize?._id
            }
          >
            <Button size="sm" colorScheme="yellow" type="button">
              {t('utility.edit')}
            </Button>
          </Link>

          <Button
            size="sm"
            colorScheme="red"
            type="button"
            onClick={() => prepateDeleteSummarize(summarize._id)}
          >
            {t('utility.delete')}
          </Button>
        </div>
      </div>

      <div></div>
    </Box>
  )
}
