import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderHourMeter = (dataHourStart, dataHourEnd) => {
  //console.log('data', data?.length)
  if (dataHourStart?.length === 0 && dataHourEnd?.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน',
          style: 'headerTable',
          alignment: 'left',
        },
        {},
        {},
      ],
      [
        {
          text: 'Hour Meter หลังใช้งาน',
          style: 'headerTable',
          alignment: 'left',
        },
        {},
        {},
      ],
    ]

    return content
  } else if (dataHourEnd.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน   ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text:
            dataHourStart?.[0]?.RippleMillNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(
                  dataHourStart?.[0]?.RippleMillNo2
                ),
          style: 'headerTable',
        },
        {},
      ],
      [
        {
          text: 'Hour Meter หลังใช้งาน   ',
          style: 'headerTable',
          alignment: 'left',
        },
        {},
        {},
      ],
    ]
    return content
  } else if (dataHourStart.length === 0) {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน   ',
          style: 'headerTable',
          alignment: 'left',
        },
        {},
        {},
      ],
      [
        {
          text: 'Hour Meter หลังใช้งาน  ',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text:
            dataHourEnd?.[0]?.RippleMillNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(
                  dataHourEnd?.[0]?.RippleMillNo2
                ),
          style: 'headerTable',
        },
        {},
      ],
    ]
    return content
  } else {
    const content = [
      [
        {
          text: 'Hour Meter เริ่มใช้งาน',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text:
            dataHourStart?.[0]?.RippleMillNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(
                  dataHourStart?.[0]?.RippleMillNo2
                ),
          style: 'headerTable',
          alignment: 'center',
        },
        {},
      ],
      [
        {
          text: 'Hour Meter หลังใช้งาน',
          style: 'headerTable',
          alignment: 'left',
        },
        {
          text:
            dataHourEnd?.[0]?.RippleMillNo2 === ''
              ? ''
              : Intl.NumberFormat('en-US').format(
                  dataHourEnd?.[0]?.RippleMillNo2
                ),
          style: 'headerTable',
          alignment: 'center',
        },
        {},
      ],
    ]

    return content
  }
}

const renderMapTime = (data, timeMap) => {
  const content = []
  _.map(timeMap, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (eachData, indexData) => {
        if (time === eachData?.TimeStart) {
          indexTime = true
          content.push([
            { text: eachData?.TimeStart || '', style: 'tableContent' },
            {
              text: eachData?.FiberCyclone['กระแส (A)'] || '-',
              style: 'tableContent',
            },
            { text: eachData?.FiberCyclone.Hz || '-', style: 'tableContent' },
            {
              text: eachData?.FiberCyclone.หมายเหตุ || '',
              style: 'tableContent',
            },
            {
              text: eachData?.ShellCyclone['กระแส (A)'] || '-',
              style: 'tableContent',
            },
            {
              text: eachData?.ShellCyclone.หมายเหตุ || '',
              style: 'tableContent',
            },
            { text: eachData?.KernelBlow || '-', style: 'tableContent' },
            {
              text: eachData?.ClayBathNo1['ค่า ถพ'] || '-',
              style: 'tableContent',
            },
            {
              text: eachData?.ClayBathNo1.แคลเซียม || '-',
              style: 'tableContent',
            },
            {
              text: eachData?.ClayBathNo2['ค่า ถพ'] || '-',
              style: 'tableContent',
            },
            {
              text: eachData?.ClayBathNo2.แคลเซียม || '-',
              style: 'tableContent',
            },
            { text: eachData?.comment || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}
const FM_PD_006_No2 = async (form, formInput, subForm, system, date) => {
  const colors = '#dddddd'
  const timeMap = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
  ]
  //find data for map in table

  const dataHourStart = _.filter(formInput?.arr, {
    HourMeter: 'เริ่มใช้งาน',
  })
  const dataHourEnd = _.filter(formInput?.arr, {
    HourMeter: 'หลังใช้งาน',
  })
  const dataRippleMill = _.filter(formInput?.arr, {
    subForm_name: 'Ripple Mill No.2',
  })
  const contentHourMeter = renderHourMeter(dataHourStart, dataHourEnd)
  const TableBody = renderMapTime(dataRippleMill, timeMap)
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [10, 59, 10, 0],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [
      {
        margin: [10, 10, 10, 0],
        table: {
          widths: ['23%', '53%', '16%', '8%'],
          body: [
            [
              {
                image: 'logo_imge',
                fit: [20, 20],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `รายงานการทำงานของเครื่องกระเทาะเมล็ด( RIPPLE MILL) - (${
                  form?.docNo || ''
                })`,
                alignment: 'center',
                bold: true,
                fontSize: 20,
                margin: [0, 0, 0, -3],
                border: [true, true, true, false],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                alignment: 'left',
                colSpan: 2,
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'THAI TALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: 12,
                border: [true, false, true, true],
              },
              {
                text: `วันที่ ${dayjs(date).format(
                  'DD เดือน MMMM พ.ศ. BBBB '
                )}`,
                alignment: 'center',
                style: 'header',
                border: [true, true, true, true],
              },
              {
                text: `บังคับใช้ : ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                alignment: 'left',
                border: [true, true, true, true],
              },
              {
                text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                style: 'header',
                alignment: 'left',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: ['13%', '37%', '50%'],
          body: [
            [
              {
                text: 'เครื่องจักร',
                style: 'headerTable',
              },
              {
                text: 'RIPPLE MILL No2',
                style: 'headerTable',
                fillColor: colors,
              },

              {
                text: '',
                style: 'headerTable',
                fillColor: colors,
              },
            ],
            ...contentHourMeter,
          ],
        },
      },
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: [
            '8%',
            '6%',
            '7%',
            '13%',
            '5.5%',
            '11.1%',
            '7%',
            '7%',
            '7%',
            '7%',
            '7%',
            '14.4%',
          ],
          body: [
            [
              //Row 1
              {
                stack: [
                  {
                    text: 'ค่าบันทึก',
                    bold: true,
                    style: 'headerTable',
                    alignment: 'left',
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 60,
                        y1: -13,
                        x2: -4,
                        y2: 33,
                        lineWidth: 1,
                        lineColor: '#000000',
                      },
                    ],
                  },
                  {
                    text: 'เวลาที่บันทึก',
                    alignment: 'right',
                    style: 'headerTable',
                    margin: [0, -16, 0, 0],
                    bold: true,
                  },
                ],
                rowSpan: 3,
              },
              {
                text: 'Fibre Cyclone',
                colSpan: 3,
                style: 'headerTable',
              },
              {},
              {},
              {
                text: 'Shell Cyclone',
                colSpan: 2,
                style: 'headerTable',
              },
              {},
              { text: 'Kernel Blow', style: 'headerTable' },
              { text: 'Clay Bath No.1', colSpan: 2, style: 'headerTable' },
              {},
              { text: 'Clay Bath No.2', colSpan: 2, style: 'headerTable' },
              {},
              { text: '', border: [true, true, true, false] },
            ],
            [
              //Row 2
              {
                text: '',
                style: 'headerTable',
                border: [true, false, false, false],
              },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'Hz',
                rowSpan: 2,
                style: 'headerTable',
                margin: [0, 4],
              },
              {
                text: 'หมายเหตุ',
                rowSpan: 2,
                style: 'headerTable',
                margin: [0, 4],
              },
              {
                text: 'กระแส',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                rowSpan: 2,
                style: 'headerTable',
                margin: [0, 4],
              },
              {
                text: '(A)',
                rowSpan: 2,
                margin: [0, 4],
                style: 'headerTable',
              },
              {
                text: 'ค่า ถ.พ.',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'แคลเซียม',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'ค่า ถ.พ.',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'แคลเซียม',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              //Row 3
              {
                text: 'เวลาที่บันทึก',
                style: 'headerTable',
                alignment: 'right',
                border: [true, false, true, true],
              },
              {
                text: '(A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {},
              {},
              {
                text: '(A)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {},
              {},
              {
                text: '1.12-1.14',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(กระสอบ)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '1.12-1.14',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '(กระสอบ)',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              { text: '', border: [true, false, true, true] },
            ],
            ...TableBody,
            [
              {
                text: 'หมายเหตุ',
                alignment: 'center',
                style: 'headerTable',
                margin: [0, 0, 0, 2],
                border: [true, false, false, false],
              },
              { text: '', border: [false, false, true, false], colSpan: 6 },
              {},
              {},
              {},
              {},
              {},
              {
                text: 'วัดความถ่วงจำเพาะทุก 3 ช.ม.',
                alignment: 'center',
                bold: true,
                margin: [0, 0, 0, 2],
                border: [true, false, true, true],
                colSpan: 5,
              },
              {},
              {},
              {},
              {},
            ],
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'ผู้รายงานกะ A 1.ลงชื่อ................................................. 2.ลงชื่อ................................................',
                alignment: 'left',
                style: 'headerTable',
                margin: [0, 0, 0, -2],
                border: [true, true, true, false],
              },
              {
                text: 'ผู้รายงานกะ B 1.ลงชื่อ...................................................... 2.ลงชื่อ......................................................',
                style: 'headerTable',
                margin: [0, 0, 0, -2],
                alignment: 'left',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: '    (.......................................)     (.......................................)  ',
                alignment: 'center',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: '    (.......................................)    (.......................................)  ',
                alignment: 'center',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: 'หัวหน้างานกะ A ลงชื่อ................................................................',
                alignment: 'left',
                style: 'headerTable',
                margin: [0, 0, 0, 0],
                border: [true, false, true, false],
              },
              {
                text: 'หัวหน้างานกะ B ลงชื่อ................................................................',
                style: 'headerTable',
                alignment: 'left',
                margin: [0, 0, 0, 0],
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: '(.........................................................)   ',
                alignment: 'center',
                style: 'headerTable',
                margin: [-100, 0, 0, 0],
                border: [true, false, true, true],
              },
              {
                text: '(.........................................................)    ',
                alignment: 'center',
                style: 'headerTable',
                margin: [-100, 0, 0, 0],
                border: [true, false, true, true],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        bold: true,
        fontSize: 14,
        margin: [0, 0, 0, -3],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [0, -2, 0, -2],
        fontSize: 13,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 12,
        margin: [0, -4, 0, -2],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_PD_006_No2
