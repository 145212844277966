/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
// components
import { Button, Link as LinkUI, Box } from '@chakra-ui/react'
import axios from 'axios'

import SpinnerLoading from '../../../components/Loading/SpinnerLoading'
import _ from 'lodash'
import GeneralFormSelector from '../../../components/Form/GeneralFormSelector'
import * as actions from '../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import BackButton from 'components/Button/BackButton'

export default function FormList() {
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const me = useSelector((state) => state.me)
  const station = useSelector((state) => state.station)
  const sensor = useSelector((state) => state.sensor)
  const form = useSelector((state) => state.form)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedForm, setSelectedForm] = useState({})
  const [date, setDate] = useState(new Date())
  const { register, handleSubmit, setValue, watch } = useForm()
  //console.log('me', me)

  useEffect(() => {
    dispatch(actions.formGet(params.id))

    return () => {}
  }, [params])

  const prop = {
    fetchDataSensor: async (sensorId, formRef) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/sensor/realtime?stationId=${form?.station}&sensorId=${sensorId}`
        )
        .then((res) => {
          // console.log('Request Server to Get an Sensors')
          if (res.data) {
            setValue(formRef, res?.data?.value)
          } else {
            setValue(formRef, 0)
          }
        })
    },
  }

  const handleUpdateValue = async (data) => {
    const confirm = window.confirm('ยืนยันการกรอกข้อมูล')

    //console.log('data ', data)
    if (confirm) {
      const dataSubmit = {
        ...data,
        name: form?.name,
        date,
        recorder: me?._id,
      }
      console.log('datasubmit', dataSubmit)
      await dispatch(actions.formInputPost(form?._id, dataSubmit))
      history.goBack()
    }
  }

  const hendleCancle = () => history.goBack()

  if (form.isLoading && !form.isCompleted) {
    return <SpinnerLoading />
  }
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              แบบฟอร์มของสถานี {station?.name}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans ">
              {form.name}
            </h2>
            <BackButton />
          </div>
        </div>
      </div>

      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(handleUpdateValue)}>
          <div className="xl:flex lg:flex flex-wrap">
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              className=" ml-4 my-2 p-4 w-auto h-36"
            >
              <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                วันที่บันทึก
              </label>
              <div>
                <SingleDatepicker
                  name="date-input"
                  date={date}
                  onDateChange={setDate}
                />
              </div>
            </Box>
            {_.map(form?.components, (eachComponent, index) => (
              <div key={index} className="w-full lg:w-1/4">
                <GeneralFormSelector
                  formData={eachComponent}
                  inUsed
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  {...prop}
                />
              </div>
            ))}
          </div>
          <div className="flex gap-2 justify-end">
            <Button
              size="md"
              colorScheme="yellow"
              onClick={() => hendleCancle()}
            >
              {t('utility.cancel')}
            </Button>

            <Button size="md" colorScheme="green" type="submit">
              {t('utility.save')}
            </Button>
          </div>{' '}
        </form>
      </div>
    </div>
  )
}
