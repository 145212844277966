import _ from 'lodash'
import dayjs from 'dayjs'

export default function SelectLogStation(dataArr, stationId) {
  let tempValueStack = []
  //console.log('dataArr', dataArr)
  // console.log('sensor Id ', sensorId)
  _.map(dataArr, (dataItem) => {
    if (dataItem?.stationId === stationId) {
      tempValueStack.push({
        date: dataItem?.date,
        value: dataItem?.value,
      })
    }
  })

  // console.log('TEMP ', tempValueStack)
  return tempValueStack
}
