/* eslint-disable operator-assignment */
/* eslint-disable no-bitwise */
/* eslint-disable no-lonely-if */
import _ from 'lodash'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

export default function CalCumulative(dataArr, name) {
  let tempValueStack = []
  let nowDate = dayjs()
  let cumulative = 0
  let cumFFB = 0
  let cumCPO = 0
  const prevday = dayjs(nowDate).subtract(1, 'month')
  let indexDate = nowDate.diff(prevday, 'day')

  _.map(dataArr, (dataItem, index) => {
    while (
      dayjs(dataItem?.date).format('YYYY-MM-DD') !==
        dayjs(nowDate).format('YYYY-MM-DD') &&
      indexDate >= 0
    ) {
      tempValueStack.push(cumulative)
      indexDate -= 1
      nowDate = dayjs(nowDate).subtract(1, 'day').format('YYYY-MM-DD')
    }
    indexDate -= 1
    nowDate = dayjs(dataItem?.date).subtract(1, 'day').format('YYYY-MM-DD')

    if (name === 'Yield') {
      cumFFB = cumFFB + parseFloat(dataItem?.PalmUsed)
      cumCPO = cumCPO + parseFloat(dataItem?.CPO)
      cumulative = (cumCPO / cumFFB) * 100
      tempValueStack.push(cumulative.toFixed(2))
    }
  })

  // let sortDate = []
  // let updateDate = dayjs(prevday).format('YYYY-MM-DD')
  // _.map(tempValueStack, (val, index) => {
  //   console.log(
  //     'MAP ',
  //     parseFloat(tempValueStack[tempValueStack?.length]),
  //     parseFloat(tempValueStack[index])
  //   )
  //   sortDate.push({
  //     x: dayjs(updateDate).format('YYYY-MM-DD'),
  //     y:
  //       parseFloat(tempValueStack[tempValueStack?.length - 1]) -
  //       parseFloat(tempValueStack[index]),
  //   })
  //   updateDate = dayjs(updateDate).add(1, 'day').format('YYYY-MM-DD')
  // })

  let dDate = dayjs()
  const prev = dayjs(dDate).subtract(1, 'month')
  let indDate = dDate.diff(prev, 'day')
  let sortDate = []
  _.map(tempValueStack, (val, index) => {
    sortDate.push({
      x: dayjs(dDate).format('YYYY-MM-DD'),
      y:
        parseFloat(val) !== 0
          ? parseFloat(val)
          : parseFloat(tempValueStack[index + 1]) === 0
          ? parseFloat(tempValueStack[index + 2])
          : parseFloat(tempValueStack[index + 1]),
    })
    indDate -= 1
    dDate = dayjs(dDate).subtract(1, 'day').format('YYYY-MM-DD')
  })

  //console.log('TEMP ', tempValueStack, sortDate)
  return sortDate
}
