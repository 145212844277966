// common type
export const ME_GET = 'ME_GET'
export const ME_RESET = 'ME_RESET'

export const USER_ALL = 'USER_ALL'
export const USER_GET = 'USER_GET'
export const USER_POST = 'USER_POST'
export const USER_PUT = 'USER_PUT'
export const USER_DEL = 'USER_DEL'

export const SYSTEM_GET = 'SYSTEM_GET'
export const SYSTEM_POST = 'SYSTEM_POST'
export const SYSTEM_PUT = 'SYSTEM_PUT'
export const SYSTEM_DEL = 'SYSTEM_DEL'

// feature type
export const STATION_ALL = 'STATION_ALL'
export const STATION_GET = 'STATION_GET'
export const STATION_POST = 'STATION_POST'
export const STATION_PUT = 'STATION_PUT'
export const STATION_DEL = 'STATION_DEL'

export const SENSOR_ALL = 'SENSOR_ALL'
export const SENSOR_GET = 'SENSOR_GET'
export const SENSOR_POST = 'SENSOR_POST'
export const SENSOR_PUT = 'SENSOR_PUT'
export const SENSOR_DEL = 'SENSOR_DEL'

export const LOG_ALL = 'LOG_ALL'
export const LOG_GET = 'LOG_GET'
export const LOG_POST = 'LOG_POST'
export const LOG_PUT = 'LOG_PUT'
export const LOG_DEL = 'LOG_DEL'

export const SUMMARIZE_ALL = 'SUMMARIZE_ALL'
export const SUMMARIZE_GET = 'SUMMARIZE_GET'
export const SUMMARIZE_POST = 'SUMMARIZE_POST'
export const SUMMARIZE_PUT = 'SUMMARIZE_PUT'
export const SUMMARIZE_DEL = 'SUMMARIZE_DEL'
export const SUMMARIZE_ERROR = 'SUMMARIZE_ERROR'

export const PREDICTION_ALL = 'PREDICTION_ALL'
export const PREDICTION_GET = 'PREDICTION_GET'
export const PREDICTION_POST = 'PREDICTION_POST'
export const PREDICTION_PUT = 'PREDICTION_PUT'
export const PREDICTION_DEL = 'PREDICTION_DEL'
export const PREDICTION_ERROR = 'PREDICTION_ERROR'

export const PREDICTION_LOG_ALL = 'PREDICTION_LOG_ALL'
export const PREDICTION_LOG_GET = 'PREDICTION_LOG_GET'
export const PREDICTION_LOG_PUT = 'PREDICTION_LOG_PUT'
export const PREDICTION_LOG_DEL = 'PREDICTION_LOG_DEL'
export const PREDICTION_LOG_ERROR = 'PREDICTION_LOG_ERROR'

export const STATION_TEMPLATE_ALL = 'STATION_TEMPLATE_ALL'
export const STATION_TEMPLATE_GET = 'STATION_TEMPLATE_GET'
export const STATION_TEMPLATE_POST = 'STATION_TEMPLATE_POST'
export const STATION_TEMPLATE_PUT = 'STATION_TEMPLATE_PUT'
export const STATION_TEMPLATE_DEL = 'STATION_TEMPLATE_DEL'

export const ELECTRIC_CONSUMPTION_ALL = 'ELECTRIC_CONSUMPTION_ALL'
export const ELECTRIC_CONSUMPTION_GET = 'ELECTRIC_CONSUMPTION_GET'
export const ELECTRIC_CONSUMPTION_POST = 'ELECTRIC_CONSUMPTION_POST'
export const ELECTRIC_CONSUMPTION_PUT = 'ELECTRIC_CONSUMPTION_PUT'
export const ELECTRIC_CONSUMPTION_DEL = 'ELECTRIC_CONSUMPTION_DEL'

export const COUNTING_SUMMARY_ALL = 'COUNTING_SUMMARY_ALL'
export const COUNTING_SUMMARY_GET = 'COUNTING_SUMMARY_GET'
export const COUNTING_SUMMARY_POST = 'COUNTING_SUMMARY_POST'
export const COUNTING_SUMMARY_PUT = 'COUNTING_SUMMARY_PUT'
export const COUNTING_SUMMARY_DEL = 'COUNTING_SUMMARY_DEL'

export const DIAGRAM_PUT = 'DIAGRAM_PUT_PUT'

export const FORM_GET = 'FORM_GET'
export const FORM_ALL = 'FORM_ALL'
export const FORM_POST = 'FORM_POST'
export const FORM_PUT = 'FORM_PUT'
export const FORM_DEL = 'FORM_DEL'
export const FORM_LOADING = 'FORM_LOADING'
export const FORM_ERROR = 'FORM_ERROR'

export const SUB_FORM_GET = 'SUB_FORM_GET'
export const SUB_FORM_ALL = 'SUB_FORM_ALL'
export const SUB_FORM_POST = 'SUB_FORM_POST'
export const SUB_FORM_PUT = 'SUB_FORM_PUT'
export const SUB_FORM_DEL = 'SUB_FORM_DEL'
export const SUB_FORM_LOADING = 'SUB_FORM_LOADING'
export const SUB_FORM_ERROR = 'SUB_FORM_ERROR'

export const FORM_INPUT_GET = 'FORM_INPUT_GET'
export const FORM_INPUT_ALL = 'FORM_INPUT_ALL'
export const FORM_INPUT_POST = 'FORM_INPUT_POST'
export const FORM_INPUT_PUT = 'FORM_INPUT_PUT'
export const FORM_INPUT_DEL = 'FORM_INPUT_DEL'
export const FORM_INPUT_LOADING = 'FORM_INPUT_LOADING'
export const FORM_INPUT_ERROR = 'FORM_INPUT_ERROR'
