import React, { useState, useEffect } from 'react'
import {
  Button,
  Link as LinkUI,
  Box,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import * as actions from '../../redux/actions/'
import _, { find } from 'lodash'
import dayjs from 'dayjs'
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp'
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp'
import { useTranslation } from 'react-i18next'

export default function BiogasDetail() {
  const params = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  //const [isLoading, setIsLoading] = useState(false)
  const summarizes = useSelector((state) => state.summarize)
  const stations = useSelector((state) => state.station)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [startTime, setStartTime] = useState(dayjs().format('YYYY-MM-DD'))
  const [endTime, setEndTime] = useState(
    dayjs(startTime).subtract(1, 'month').format('YYYY-MM-DD')
  )
  const formInput = useSelector((state) => state.formInput)

  useEffect(() => {
    dispatch(actions.summarizeAll())
    dispatch(actions.stationAll())
    dispatch(actions.formInputSummarize(endTime, startTime)).then(() =>
      setIsLoading(true)
    )
    return () => {}
  }, [params])

  // console.log('formInput', formInput?.arr)

  const allFuel = _.map(formInput?.arr, (fm) => {
    return fm?.[3]
  })
  //console.log(allFuel)

  const electricGen = _.map(formInput?.arr, (fm) => {
    return fm?.[4]
  })

  const findStation = (id) => {
    const data = _?.find(stations?.arr, (each) => each?._id === id)
    return data
  }

  const findData = (id) => {
    const varStation = findStation('647f4a8cc359d800123681e1')
    if (id === '65b0cdd09985d6174c098f47') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '65b22465fb3863618455dee0') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '65b2252bfb3863618455e05a') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else {
      return null
    }
    //console.log('selectvariable', data)
    //console.log('station?.variable', station?.variables)
    //setSelectVariable(data)
  }

  const displayAlert = (name) => {
    if (name === 'ปริมาณไอน้ำที่ผลิตได้') {
      if (
        parseFloat(findData('65b0cdd09985d6174c098f47')?.numericStatus) >
          319.2 &&
        parseFloat(findData('65b0cdd09985d6174c098f47')?.numericStatus) < 1082.4
      ) {
        return (
          <StatNumber textColor="blue.400">
            {Intl.NumberFormat('en-US').format(
              Number(
                parseFloat(findData('65b0cdd09985d6174c098f47')?.numericStatus)
              ).toFixed(2)
            )}
            {' ton/d'}
          </StatNumber>
        )
      } else if (
        parseFloat(findData('65b0cdd09985d6174c098f47')?.numericStatus) < 319.2
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {parseFloat(
                findData('65b0cdd09985d6174c098f47')?.numericStatus
              ).toFixed(2)}{' '}
              {' ton/d'}
            </StatNumber>
            <StatLabel>
              <ArrowDropDownSharpIcon /> {'ต่ำกว่ากำหนด '}
              {(
                319.2 -
                parseFloat(findData('65b0cdd09985d6174c098f47')?.numericStatus)
              ).toFixed(2)}
              {' ton/d'}
            </StatLabel>
          </Stat>
        )
      } else if (
        parseFloat(findData('65b0cdd09985d6174c098f47')?.numericStatus) > 1082.4
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {parseFloat(
                findData('65b0cdd09985d6174c098f47')?.numericStatus
              ).toFixed(2)}{' '}
              {' ton/d'}
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {(
                parseFloat(
                  findData('65b0cdd09985d6174c098f47')?.numericStatus
                ) - 1082.4
              ).toFixed(2)}
              {' ton/d'}
            </StatLabel>
          </Stat>
        )
      }
    } else if (name === 'ปริมาณเชื้อเพลิงที่ใช้') {
      if (
        allFuel[3]?.length !== 0 &&
        parseFloat(allFuel[3]?.QuantityPalm).toFixed(2) > 144.37 &&
        parseFloat(allFuel[3]?.QuantityPalm).toFixed(2) < 271.29
      ) {
        return (
          <StatNumber textColor="blue.400">
            {Intl.NumberFormat('en-US').format(
              Number(allFuel[3]?.QuantityPalm).toFixed(2)
            )}
            {' ton/d'}
          </StatNumber>
        )
      } else if (
        allFuel[3]?.length === 0 ||
        (allFuel[3]?.length === undefined &&
          parseFloat(
            findData('65b22465fb3863618455dee0')?.numericStatus.toFixed(2)
          ) > 144.37 &&
          parseFloat(
            findData('65b22465fb3863618455dee0')?.numericStatus.toFixed(2)
          ) < 271.29)
      ) {
        return (
          <StatNumber textColor="blue.400">
            {parseFloat(
              findData('65b22465fb3863618455dee0')?.numericStatus
            ).toFixed(2)}{' '}
            {' ton/d'}
          </StatNumber>
        )
      } else if (
        allFuel[3]?.length !== 0 &&
        parseFloat(allFuel[3]?.QuantityPalm).toFixed(2) < 144.37
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {Intl.NumberFormat('en-US').format(
                Number(allFuel[3]?.QuantityPalm).toFixed(2)
              )}
              {' ton/d'}
            </StatNumber>
            <StatLabel>
              <ArrowDropDownSharpIcon /> {'ต่ำกว่ากำหนด '}
              {(
                144.37 - parseFloat(allFuel[3]?.QuantityPalm).toFixed(2)
              ).toFixed(2)}
              {' ton/d'}
            </StatLabel>
          </Stat>
        )
      } else if (
        allFuel[3]?.length !== 0 &&
        parseFloat(allFuel[3]?.QuantityPalm).toFixed(2) > 271.29
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {Intl.NumberFormat('en-US').format(
                Number(allFuel[3]?.QuantityPalm).toFixed(2)
              )}
              {' ton/d'}
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {(
                parseFloat(allFuel[3]?.QuantityPalm).toFixed(2) - 271.29
              ).toFixed(2)}
              {' ton/d'}
            </StatLabel>
          </Stat>
        )
      } else if (
        allFuel[3]?.length === 0 ||
        (allFuel[3]?.length === undefined &&
          parseFloat(
            findData('65b22465fb3863618455dee0')?.numericStatus.toFixed(2)
          ) < 144.37)
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {parseFloat(
                findData('65b22465fb3863618455dee0')?.numericStatus
              ).toFixed(2)}{' '}
              {' ton/d'}
            </StatNumber>
            <StatLabel>
              <ArrowDropDownSharpIcon /> {'ต่ำกว่ากำหนด '}
              {(
                144.37 -
                parseFloat(findData('65b22465fb3863618455dee0'))?.numericStatus
              ).toFixed(2)}{' '}
              {' ton/d'}
            </StatLabel>
          </Stat>
        )
      } else if (
        allFuel[3]?.length === 0 ||
        (allFuel[3]?.length === undefined &&
          parseFloat(
            findData('65b22465fb3863618455dee0')?.numericStatus.toFixed(2)
          ) > 271.29)
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {parseFloat(
                findData('65b22465fb3863618455dee0')?.numericStatus
              ).toFixed(2)}{' '}
              {' ton/d'}
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {(
                parseFloat(
                  findData('65b22465fb3863618455dee0')?.numericStatus
                ) - 271.29
              ).toFixed(2)}{' '}
              {' ton/d'}
            </StatLabel>
          </Stat>
        )
      } else {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {'N/A'} {' ton/d'}
            </StatNumber>
          </Stat>
        )
      }
    } else if (name === 'ปริมาณไฟฟ้าที่ผลิตได้') {
      if (
        electricGen[4]?.length !== 0 &&
        parseFloat(electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(2) >
          1464 &&
        parseFloat(electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(2) <
          32322
      ) {
        return (
          <StatNumber textColor="blue.400">
            {Intl.NumberFormat('en-US').format(
              Number(
                parseFloat(
                  electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต
                ).toFixed(2)
              ).toFixed(2)
            )}
            {' KWh/day'}
          </StatNumber>
        )
      } else if (
        electricGen[4]?.length === 0 ||
        (electricGen[4]?.length === undefined &&
          parseFloat(
            findData('65b2252bfb3863618455e05a')?.numericStatus.toFixed(2)
          ) > 1464 &&
          parseFloat(
            findData('65b2252bfb3863618455e05a')?.numericStatus.toFixed(2)
          ) < 32322)
      ) {
        return (
          <StatNumber textColor="blue.400">
            {parseFloat(
              findData('65b2252bfb3863618455e05a')?.numericStatus
            ).toFixed(2)}{' '}
            {' KWh/day'}
          </StatNumber>
        )
      } else if (
        electricGen[4]?.length !== 0 &&
        parseFloat(electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(2) <
          1464
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {Intl.NumberFormat('en-US').format(
                Number(electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(
                  2
                )
              )}
              {' KWh/day'}
            </StatNumber>
            <StatLabel>
              <ArrowDropDownSharpIcon /> {'ต่ำกว่ากำหนด '}
              {(
                1464 -
                parseFloat(
                  electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต
                ).toFixed(2)
              ).toFixed(2)}
              {' KWh/day'}
            </StatLabel>
          </Stat>
        )
      } else if (
        electricGen[4]?.length !== 0 &&
        parseFloat(electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(2) >
          32322
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {Intl.NumberFormat('en-US').format(
                Number(electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(
                  2
                )
              )}
              {' KWh/day'}
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {(
                parseFloat(
                  electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต
                ).toFixed(2) - 32322
              ).toFixed(2)}
              {' KWh/day'}
            </StatLabel>
          </Stat>
        )
      } else if (
        electricGen[4]?.length === 0 ||
        (electricGen[4]?.length === undefined &&
          parseFloat(
            findData('65b2252bfb3863618455e05a')?.numericStatus.toFixed(2)
          ) < 1464)
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {parseFloat(
                findData('65b2252bfb3863618455e05a')?.numericStatus
              ).toFixed(2)}{' '}
              {' KWh/day'}
            </StatNumber>
            <StatLabel>
              <ArrowDropDownSharpIcon /> {'ต่ำกว่ากำหนด '}
              {(
                1464 -
                parseFloat(findData('65b2252bfb3863618455e05a'))?.numericStatus
              ).toFixed(2)}{' '}
              {' KWh/day'}
            </StatLabel>
          </Stat>
        )
      } else if (
        electricGen[4]?.length === 0 ||
        (electricGen[4]?.length === undefined &&
          parseFloat(
            findData('65b2252bfb3863618455e05a')?.numericStatus.toFixed(2)
          ) > 32322)
      ) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {parseFloat(
                findData('65b2252bfb3863618455e05a')?.numericStatus
              ).toFixed(2)}{' '}
              {' KWh/day'}
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {(
                parseFloat(
                  findData('65b2252bfb3863618455e05a')?.numericStatus
                ) - 32322
              ).toFixed(2)}{' '}
              {' KWh/day'}
            </StatLabel>
          </Stat>
        )
      } else {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {'N/A'} {' KWh/day'}
            </StatNumber>
          </Stat>
        )
      }
    }
  }

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded ">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              การสรุปผลการดำเนินงาน
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              รายการสรุป Biomass{' '}
            </h2>
          </div>
        </div>

        <div className="flex justify-between my-2">
          <Link to={'/summarize'}>
            <Button variant="outline" size="sm">
              กลับ
            </Button>
          </Link>
        </div>
        <div className="flex flex-wrap p-4 w-full bg-gray-200 rounded-sm">
          <div className="w-full">
            <div className="my-2 flex justify-between"></div>
            <div className="mb-10 flex justify-center gap-1 grid-cols-2">
              <Box
                // w={[60, '2xl']}
                // h="150px"
                borderWidth="3px"
                borderRadius="lg"
                className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25 "
              >
                <Stat>
                  <StatLabel fontSize={['sm', 'lg']}>
                    <span className="flex justify-center font-bold ">
                      {'ปริมาณไอน้ำที่ผลิตได้'}
                    </span>
                  </StatLabel>
                  {displayAlert('ปริมาณไอน้ำที่ผลิตได้')}

                  <StatHelpText>
                    {dayjs(findData('65b0cdd09985d6174c098f47')?.lastDate)
                      .locale('th')
                      .format('DD MMM BBBB')}
                  </StatHelpText>
                </Stat>
              </Box>
              <Box
                // w={[60, '2xl']}
                // h="150px"
                borderWidth="3px"
                borderRadius="lg"
                className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25 "
              >
                <Stat>
                  <StatLabel fontSize={['sm', 'lg']}>
                    <span className="flex justify-center font-bold ">
                      {'ปริมาณเชื้อเพลิงที่ใช้'}
                    </span>
                  </StatLabel>
                  {displayAlert('ปริมาณเชื้อเพลิงที่ใช้')}

                  <StatHelpText>
                    {allFuel[3]?.length === 0 || allFuel[3] === undefined
                      ? dayjs(findData('65b22465fb3863618455dee0')?.lastDate)
                          .locale('th')
                          .format('DD MMM BBBB')
                      : dayjs(allFuel[3]?.date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                  </StatHelpText>
                </Stat>
              </Box>
              <Box
                // w={[60, '2xl']}
                // h="150px"
                borderWidth="3px"
                borderRadius="lg"
                className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25 "
              >
                <Stat>
                  <StatLabel fontSize={['sm', 'lg']}>
                    <span className="flex justify-center font-bold ">
                      {'ปริมาณไฟฟ้าที่ผลิตได้'}
                    </span>
                  </StatLabel>
                  {displayAlert('ปริมาณไฟฟ้าที่ผลิตได้')}
                  {/* <StatNumber textColor="blue.400">
                    {electricGen[4]?.length === 0 ||
                    electricGen[4] === undefined
                      ? Intl.NumberFormat('en-US').format(
                          Number(
                            findData(
                              '65b2252bfb3863618455e05a'
                            )?.numericStatus.toFixed(2)
                          ).toFixed(2)
                        )
                      : Intl.NumberFormat('en-US').format(
                          Number(
                            parseFloat(
                              electricGen[4]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต
                            ).toFixed(2)
                          ).toFixed(2)
                        )}
                    {' KWh/day'}
                  </StatNumber> */}
                  <StatHelpText>
                    {electricGen[4]?.length === 0 ||
                    electricGen[4] === undefined
                      ? dayjs(findData('65b2252bfb3863618455e05a')?.lastDate)
                          .locale('th')
                          .format('DD MMM BBBB')
                      : dayjs(electricGen[4]?.date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                  </StatHelpText>
                </Stat>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
