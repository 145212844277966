import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../redux/actions'
import { ErrorBoundary } from 'react-error-boundary'

// components

import Sidebar from '../components/common/Sidebar/mainSidebar'
import FooterAdmin from '../components/common/Footers/FooterAdmin.js'
import SpinnerLoading from '../components/Loading/SpinnerLoading'
import Fallback from '../components/error/Fallback'

// views
import HumanInputStationList from '../views/human-input/HumanInputStationList'
import FormList from '../views/human-input/FormList'
import RenderForm from '../views/human-input/components/RenderForm'
import StationList from '../views/human-input/StationList'
import ReportStationList from '../views/human-input/ReportStationList'
import ReportFormList from '../views/human-input/ReportFormList'
import ReportDetail from '../views/human-input/ReportDetail'
import SubFormList from '../views/human-input/components/SubFormList'
import RenderSubForm from '../views/human-input/components/RenderSubForm'
import InputHistory from '../views/human-input/InputHistory'
import InputHistoryFormList from 'views/human-input/InputHistoryFormList'
import InputHistoryDetail from 'views/human-input/InputHistoryDetail'
import RenderFormHistory from 'views/human-input/components/RenderFormHistory'
import * as UserRole from '../config/UserRoles'
import EditHistoryFrom from '../views/human-input/EditHistoryFrom'

export default function StatisticsLayout() {
  const history = useHistory()
  const authorize_role = [
    UserRole.ROLES.USER,
    UserRole.ROLES.GENERAL,
    UserRole.ROLES.ADMIN,
    UserRole.ROLES.SUPER_USER,
  ] // Role ที่ได้รับอนุญาต

  // eslint-disable-next-line camelcase
  const storage_remember = JSON.parse(window.localStorage.eiot_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log('COUNTING SUMMARY Layout : ACTIVE')
    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.meGet(storage_remember.uid))
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      if (me?.role !== authorize_role[0] && system.enable_statistics) {
        setIsLoading(true)
        console.log('ได้รับการอนุญาตเข้าถึง')
      } else {
        console.log('ไม่ได้รับอนุญาต')
        alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้')
        history.push('/')
      }
    }

    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
          <ErrorBoundary
            FallbackComponent={Fallback}
            onReset={() => {
              history.goBack()
            }}
          >
            <Switch>
              <Route
                path="/humaninput"
                exact
                component={HumanInputStationList}
              />
              <Route
                path="/humaninput/stationlist/"
                exact
                component={StationList}
              />

              <Route
                path="/humaninput/report/stationlist"
                exact
                component={ReportStationList}
              />
              <Route
                path="/humaninput/report/form/:id"
                exact
                component={ReportFormList}
              />
              <Route
                path="/humaninput/report/form/detail/:id"
                exact
                component={ReportDetail}
              />
              <Route
                path="/humaninput/stationlist/:id"
                exact
                component={FormList}
              />
              <Route
                path="/humaninput/history/"
                exact
                component={InputHistory}
              />
              <Route
                path="/humaninput/history/formList/:id"
                exact
                component={InputHistoryFormList}
              />
              <Route
                path="/humaninput/history/formList/historyDetail/:id"
                exact
                component={InputHistoryDetail}
              />
              <Route
                path="/humaninput/history/formList/renderFormHistory/:id"
                exact
                component={RenderFormHistory}
              />
              <Route
                path="/humaninput/subformList/:id"
                exact
                component={SubFormList}
              />
              <Route
                path="/humaninput/subformList/add/:formId/:id"
                exact
                component={RenderSubForm}
              />
              <Route
                path="/humaninput/formlist/:id"
                exact
                component={RenderForm}
              />
              <Route
                path="/humaninput/formlist/:id"
                exact
                component={RenderForm}
              />
              <Route
                path="/humaninput/history/formList/historyDetail/edit/:form/:formId/:id"
                exact
                component={EditHistoryFrom}
              />
              <Redirect from="/humaninput" to="/humaninput" />
            </Switch>
          </ErrorBoundary>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <div>
      <SpinnerLoading />
    </div>
  )
}
