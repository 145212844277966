/* eslint-disable no-restricted-globals */
import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { fa0 } from '@fortawesome/free-solid-svg-icons'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const LabYieldReport = async (form, formInput, subForm, system, date) => {
  const colorsRed = '#fd9999'

  const DataYeild = _.filter(formInput?.arr, {
    subForm_name: 'Yield ที่ได้',
  })
  const OilLoss = _.filter(formInput?.arr, {
    subForm_name: 'ข้อมูลต่างๆ',
  })
  console.log('OilLoss', OilLoss)
  const P1 = _.filter(formInput?.arr, {
    subForm_name: 'Stock Tank',
    StockTank: 'P1 (CPO)',
  })

  const P2 = _.filter(formInput?.arr, {
    subForm_name: 'Stock Tank',
    StockTank: 'P2 (CPO)',
  })
  const P3 = _.filter(formInput?.arr, {
    subForm_name: 'Stock Tank',
    StockTank: 'P3 (CPO)',
  })
  const P4 = _.filter(formInput?.arr, {
    subForm_name: 'Stock Tank',
    StockTank: 'P4 (CPKO)',
  })
  const P5 = _.filter(formInput?.arr, {
    subForm_name: 'Stock Tank',
    StockTank: 'P5 (CPKO)',
  })
  const AD = _.filter(formInput?.arr, {
    subForm_name: 'Stock Tank',
    StockTank: 'AD',
  })
  const CPO = _.filter(formInput?.arr, {
    subForm_name: 'Stock Tank',
    StockTank: 'CPO ขาย',
  })
  const CPK = _.filter(formInput?.arr, {
    subForm_name: 'Stock Tank',
    StockTank: 'CPKO ขาย',
  })

  const Lantea = _.filter(formInput?.arr, {
    subForm_name: 'สัดส่วนปาล์มที่ใช้ผลิต',
    ProbPalm: 'ปาล์มลานเท',
  })

  const PalmSuan = _.filter(formInput?.arr, {
    subForm_name: 'สัดส่วนปาล์มที่ใช้ผลิต',
    ProbPalm: 'ปาล์มสวน',
  })

  const ProcessCPKO = _.filter(formInput?.arr, {
    subForm_name: 'เช็คค่าสี CPKO',
    STD: 'Process',
  })
  const CPKO = _.filter(formInput?.arr, {
    subForm_name: 'เช็คค่าสี CPKO',
  })

  const P5CPKO = _.filter(formInput?.arr, {
    subForm_name: 'เช็คค่าสี CPKO',
    STD: 'P5',
  })

  const PCPO = _.filter(formInput?.arr, {
    subForm_name: 'เช็คค่าสี CPO',
  })

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 20, 20, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header: [],
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        table: {
          widths: ['25%', '*'],
          body: [
            [
              {
                text: 'TTO3',
                bold: true,
                fontSize: 16,
                border: [false, false, false, false],
              },
              {
                text: `แลปรายงานยิลด์ โรง 3 : วันที่ผลิต ${dayjs(date).format(
                  'D เดือน MMMM พ.ศ. BBBB \t'
                )}`,
                style: 'headerTable',
                fontSize: '15',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 10],
        table: {
          widths: ['17%', '14%', '14%', '14%', '14%', '9.33%', '9.33%'],
          body: [
            [
              { text: 'ยอดปาล์มที่ใช้ผลิต', style: 'headerTable' },
              {
                text: isNaN(DataYeild[0]?.PalmUsed)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.PalmUsed).toFixed(2)
                    ),
                style: 'tableContent',
              },
              { text: 'ตัน', style: 'headerTable' },
              { text: 'ได้ CPO', colSpan: 2, style: 'headerTable' },
              { text: '', style: 'headerTable' },
              {
                text: isNaN(DataYeild[0]?.CPO)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.CPO).toFixed(2)
                    ),
                style: 'tableContent',
              },
              { text: 'ตัน', style: 'headerTable' },
            ],

            [
              { text: 'Yeild ที่ได้', style: 'headerTable' },
              {
                text: isNaN(DataYeild[0]?.Yield)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.Yield).toFixed(2)
                    ),
                style: 'tableContent',
              },
              { text: '%', style: 'headerTable' },
              {
                text: 'ถ้ารวม loss ที่เกินจากLimit จะได้ ',
                style: 'headerTable',
                colSpan: 2,
              },
              { text: '', style: 'headerTable' },
              {
                text: isNaN(DataYeild[0]?.LossOverLimit)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.LossOverLimit).toFixed(2)
                    ),
                style: 'tableContent',
              },
              { text: '%', style: 'headerTable' },
            ],
          ],
        },
      },
      {
        margin: [0, 10],
        table: {
          widths: ['17%', '13%', '13%', '14%', '14%'],
          body: [
            [
              { text: '%Oil Loss', style: 'headerTable' },
              { text: 'Oil/Dry', style: 'headerTable' },
              { text: 'Oil/Basis', style: 'headerTable' },
              { text: 'จำนวนตัน', style: 'headerTable' },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            [
              { text: 'Press Cake Loss (7.0%)', style: 'tableContent' },
              {
                text: isNaN(DataYeild[0]?.PressCakeLoss?.['Oil/Dry'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.PressCakeLoss?.['Oil/Dry']).toFixed(
                        2
                      )
                    ),
                fillColor:
                  DataYeild[0]?.PressCakeLoss?.['Oil/Dry'] > 7.0
                    ? '#fd9999'
                    : '',
                style: 'tableContent',
              }, //กรอก
              {
                text: isNaN(DataYeild[0]?.PressCakeLoss?.['Oil/Basis'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(
                        DataYeild[0]?.PressCakeLoss?.['Oil/Basis']
                      ).toFixed(2)
                    ),
                fillColor:
                  DataYeild[0]?.PressCakeLoss?.['Oil/Basis'] > 4.5
                    ? '#fd9999'
                    : '',
                style: 'tableContent',
              }, //กรอก
              {
                text: isNaN(DataYeild[0]?.PressCakeLoss?.จำนวนตัน)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.PressCakeLoss?.จำนวนตัน).toFixed(2)
                    ),
                style: 'tableContent', //จำนวนตัน
              },
              {
                text:
                  DataYeild[0]?.PressCakeLoss?.จำนวนตัน === '0'
                    ? 'Loss ไม่เกิน Limit'
                    : '',
                border: [false, false, false, false],
                style: 'tableContent',
              },
            ],
            [
              { text: 'Cake Decanter (9.0%)', style: 'tableContent' },
              {
                text: isNaN(DataYeild[0]?.CakeDecenter?.['Oil/Dry'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.CakeDecenter?.['Oil/Dry']).toFixed(2)
                    ),
                fillColor:
                  DataYeild[0]?.CakeDecenter?.['Oil/Dry'] > 9.0
                    ? '#fd9999'
                    : '',
                style: 'tableContent',
              },
              {
                text: isNaN(DataYeild[0]?.CakeDecenter?.['Oil/Basis'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.CakeDecenter?.['Oil/Basis']).toFixed(
                        2
                      )
                    ),
                fillColor:
                  DataYeild[0]?.CakeDecenter?.['Oil/Basis'] > 3.5
                    ? '#fd9999'
                    : '',
                style: 'tableContent',
              },
              {
                text: isNaN(DataYeild[0]?.CakeDecenter?.จำนวนตัน)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.CakeDecenter?.จำนวนตัน).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text:
                  DataYeild[0]?.CakeDecenter?.จำนวนตัน === '0'
                    ? 'Loss ไม่เกิน Limit'
                    : '',
                border: [false, false, false, false],
                style: 'tableContent',
              },
            ],
            [
              { text: 'Waste Water (10.0%)', style: 'tableContent' },
              {
                text: isNaN(DataYeild[0]?.wasteWater?.['Oil/Dry'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.wasteWater?.['Oil/Dry']).toFixed(2)
                    ),
                fillColor:
                  DataYeild[0]?.wasteWater?.['Oil/Dry'] > 10.0 ? '#fd9999' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(DataYeild[0]?.wasteWater?.['Oil/Basis'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.wasteWater?.['Oil/Basis']).toFixed(2)
                    ),
                fillColor:
                  DataYeild[0]?.wasteWater?.['Oil/Basis'] > 1.0
                    ? '#fd9999'
                    : '',
                style: 'tableContent',
              },
              {
                text: isNaN(DataYeild[0]?.wasteWater?.จำนวนตัน)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.wasteWater?.จำนวนตัน).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text:
                  DataYeild[0]?.wasteWater?.จำนวนตัน === '0'
                    ? 'Loss ไม่เกิน Limit'
                    : '',
                border: [false, false, false, false],
                style: 'tableContent',
              },
            ],
            [
              {
                text: 'ปริมาณสูญเสียนํ้ามันที่เกินค่าควบคุม',
                colSpan: 3,
                style: 'headerTable',
              },
              { text: '', style: 'headerTable' },
              { text: '', style: 'headerTable' },
              {
                text: isNaN(DataYeild[0]?.LossOverControl)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.LossOverControl).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Bunch Press',
                style: 'headerTable',
                border: [true, true, false, true],
              },
              {
                text: '(STD Moisture 50% Max)',
                style: 'headerTable',
                colSpan: 2,
                border: [false, true, true, true],
              },
              { text: '', style: 'tableContent' },

              {
                text: isNaN(DataYeild[0]?.BunchPress)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(DataYeild[0]?.BunchPress).toFixed(2)
                    ),
                fillColor: DataYeild[0]?.BunchPress > 50.0 ? '#fd9999' : '',
                style: 'headerTable',
              },

              {
                text: '',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 10],
        table: {
          heights: [1],
          widths: ['11%', '11%', '13%', '13%', '12%', '13%', '9%', '9%', '9%'],
          body: [
            [
              { text: 'Yield เมล็ดใน', style: 'headerTable' },
              {
                text: isNaN(OilLoss[0]?.YieldNut)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(OilLoss[0]?.YieldNut).toFixed(2)
                    ),
                fillColor: OilLoss[0]?.YieldNut < 5.0 ? '#fd9999' : '',
                style: 'tableContent',
              },
              { text: '%(STD>5%)', style: 'headerTable' },
              { text: 'Yeild CPKO', style: 'headerTable' },
              {
                text: isNaN(OilLoss[0]?.YieldCPKO)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(OilLoss[0]?.YieldCPKO).toFixed(2)
                    ),
                fillColor: OilLoss[0]?.YieldCPKO < 42.0 ? '#fd9999' : '',
                style: 'tableContent',
              },
              { text: '%(STD>42%)', style: 'headerTable' },
              { text: 'CPKO/FFB', style: 'headerTable' },
              {
                text: isNaN(OilLoss[0]?.CPKOFFB)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(OilLoss[0]?.CPKOFFB).toFixed(2)
                    ),
                fillColor: OilLoss[0]?.CPKOFFB < 2.1 ? '#fd9999' : '',
                style: 'tableContent',
              },
              { text: '(STD>2.1%)', style: 'headerTable' },
            ],
            [
              { text: 'Fiber Cyclone', style: 'headerTable' },
              {
                text: isNaN(OilLoss[0]?.FiberCyclone)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(OilLoss[0]?.FiberCyclone).toFixed(2)
                    ),
                fillColor: OilLoss[0]?.FiberCyclone > 0.8 ? '#fd9999' : '',
                style: 'tableContent',
              },
              { text: '%(STD<0.8%)', style: 'headerTable' },
              {
                text: 'Shell Claybath',
                style: 'headerTable',
              },
              {
                text: isNaN(OilLoss[0]?.ShellClaybath)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(OilLoss[0]?.ShellClaybath).toFixed(2)
                    ),
                fillColor: OilLoss[0]?.ShellClaybath > 0.8 ? '#fd9999' : '',
                style: 'tableContent',
              },
              { text: '%(STD<0.8%)', style: 'headerTable' },
              { text: '', style: 'headerTable', colSpan: 3, rowSpan: 2 },
              {},
              {},
            ],
            [
              { text: 'Shell Cyclone', style: 'headerTable' },
              {
                text: isNaN(OilLoss[0]?.ShellCyclone)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(OilLoss[0]?.ShellCyclone).toFixed(2)
                    ),
                fillColor: OilLoss[0]?.ShellCyclone > 0.8 ? '#fd9999' : '',
                style: 'tableContent',
              },
              { text: '%(STD<0.8%)', style: 'headerTable' },
              {
                text: '%กะลาในกากฯ',
                style: 'headerTable',
              },
              {
                text: isNaN(OilLoss[0]?.NutFiber)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(OilLoss[0]?.NutFiber).toFixed(2)
                    ),
                fillColor: OilLoss[0]?.NutFiber > 9.0 ? '#fd9999' : '',
                style: 'tableContent',
              },
              { text: '%(STD<9%)', style: 'headerTable' },
              { text: '', style: 'headerTable', colSpan: 3 },
              {},
              {},
            ],
          ],
        },
      },
      {
        margin: [0, 10],
        table: {
          heights: [1],
          widths: ['8%', '8%', '14%', '14%', '14%', '14%', '27.99%'],
          body: [
            [
              { text: 'stock Tank No.', style: 'headerTable' },
              { text: '%FFA', style: 'headerTable' },
              { text: '%M', style: 'headerTable' },
              { text: 'Dobi\n(บน กลาง ล่าง)', style: 'headerTable' },
              { text: 'IV', style: 'headerTable' },
              { text: 'Temp(C)', style: 'headerTable' },
              { text: 'ปริมาณนํ้ามัน (ตัน)', style: 'headerTable' },
            ],
            [
              { text: 'P1 (CPO)', style: 'headerTable' },
              {
                text: isNaN(P1[0]?.FFA)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P1[0]?.FFA).toFixed(2)
                    ),
                fillColor: isNaN(P1[0]?.FFA) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P1[0]?.M)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P1[0]?.M).toFixed(2)
                    ),
                fillColor: isNaN(P1[0]?.M) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P1[0]?.Dobi)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P1[0]?.Dobi).toFixed(2)
                    ),
                fillColor: isNaN(P1[0]?.Dobi) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P1[0]?.IV)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P1[0]?.IV).toFixed(2)
                    ),
                fillColor: isNaN(P1[0]?.IV) ? '#dddddd' : '',
                style: 'tableContent',
              },

              {
                text: isNaN(P1[0]?.Temp)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P1[0]?.Temp).toFixed(2)
                    ),
                fillColor: isNaN(P1[0]?.Temp) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P1[0]?.OilQuantity)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P1[0]?.OilQuantity).toFixed(2)
                    ),
                fillColor: isNaN(P1[0]?.OilQuantity) ? '#dddddd' : '',
                style: 'tableContent',
              },
            ],
            [
              { text: 'P2 (CPO)', style: 'headerTable' },
              {
                text: isNaN(P2[0]?.FFA)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P2[0]?.FFA).toFixed(2)
                    ),
                fillColor: isNaN(P2[0]?.FFA) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P2[0]?.M)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P2[0]?.M).toFixed(2)
                    ),
                fillColor: isNaN(P2[0]?.M) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P2[0]?.Dobi)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P2[0]?.Dobi).toFixed(2)
                    ),
                fillColor: isNaN(P2[0]?.Dobi) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P2[0]?.IV)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P2[0]?.IV).toFixed(2)
                    ),
                fillColor: isNaN(P2[0]?.IV) ? '#dddddd' : '',
                style: 'tableContent',
              },

              {
                text: isNaN(P2[0]?.Temp)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P2[0]?.Temp).toFixed(2)
                    ),
                fillColor: isNaN(P2[0]?.Temp) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P2[0]?.OilQuantity)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P2[0]?.OilQuantity).toFixed(2)
                    ),
                fillColor: isNaN(P2[0]?.OilQuantity) ? '#dddddd' : '',
                style: 'tableContent',
              },
            ],
            [
              { text: 'P3 (CPO)', style: 'headerTable' },
              {
                text: isNaN(P3[0]?.FFA)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P3[0]?.FFA).toFixed(2)
                    ),
                fillColor: isNaN(P3[0]?.FFA) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P3[0]?.M)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P3[0]?.M).toFixed(2)
                    ),
                fillColor: isNaN(P3[0]?.M) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P3[0]?.Dobi)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P3[0]?.Dobi).toFixed(2)
                    ),
                fillColor: isNaN(P3[0]?.Dobi) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P3[0]?.IV)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P3[0]?.IV).toFixed(2)
                    ),
                fillColor: isNaN(P3[0]?.IV) ? '#dddddd' : '',
                style: 'tableContent',
              },

              {
                text: isNaN(P3[0]?.Temp)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P3[0]?.Temp).toFixed(2)
                    ),
                fillColor: isNaN(P3[0]?.Temp) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P3[0]?.OilQuantity)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P3[0]?.OilQuantity).toFixed(2)
                    ),
                fillColor: isNaN(P3[0]?.OilQuantity) ? '#dddddd' : '',
                style: 'tableContent',
              },
            ],
            [
              { text: 'P4 (CPKO)', style: 'headerTable' },
              {
                text: isNaN(P4[0]?.FFA)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P4[0]?.FFA).toFixed(2)
                    ),
                fillColor: isNaN(P4[0]?.FFA) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P4[0]?.M)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P4[0]?.M).toFixed(2)
                    ),
                fillColor: isNaN(P4[0]?.M) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P4[0]?.Dobi)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P4[0]?.Dobi).toFixed(2)
                    ),
                fillColor: isNaN(P4[0]?.Dobi) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P4[0]?.IV)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P4[0]?.IV).toFixed(2)
                    ),
                fillColor: isNaN(P4[0]?.IV) ? '#dddddd' : '',
                style: 'tableContent',
              },

              {
                text: isNaN(P4[0]?.Temp)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P4[0]?.Temp).toFixed(2)
                    ),
                fillColor: isNaN(P4[0]?.Temp) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P4[0]?.OilQuantity)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P4[0]?.OilQuantity).toFixed(2)
                    ),
                fillColor: isNaN(P4[0]?.OilQuantity) ? '#dddddd' : '',
                style: 'tableContent',
              },
            ],
            [
              { text: 'P5 (CPKO)', style: 'headerTable' },
              {
                text: isNaN(P5[0]?.FFA)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P5[0]?.FFA).toFixed(2)
                    ),
                fillColor: isNaN(P5[0]?.FFA) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P5[0]?.M)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P5[0]?.M).toFixed(2)
                    ),
                fillColor: isNaN(P5[0]?.M) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P5[0]?.Dobi)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P5[0]?.Dobi).toFixed(2)
                    ),
                fillColor: isNaN(P5[0]?.Dobi) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P5[0]?.IV)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P5[0]?.IV).toFixed(2)
                    ),
                fillColor: isNaN(P5[0]?.IV) ? '#dddddd' : '',
                style: 'tableContent',
              },

              {
                text: isNaN(P5[0]?.Temp)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P5[0]?.Temp).toFixed(2)
                    ),
                fillColor: isNaN(P5[0]?.Temp) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(P5[0]?.OilQuantity)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(P5[0]?.OilQuantity).toFixed(2)
                    ),
                fillColor: isNaN(P5[0]?.OilQuantity) ? '#dddddd' : '',
                style: 'tableContent',
              },
            ],
            [
              { text: 'AD', style: 'headerTable' },
              {
                text: isNaN(AD[0]?.FFA)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(AD[0]?.FFA).toFixed(2)
                    ),
                fillColor: isNaN(AD[0]?.FFA) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(AD[0]?.M)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(AD[0]?.M).toFixed(2)
                    ),
                fillColor: isNaN(AD[0]?.M) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(AD[0]?.Dobi)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(AD[0]?.Dobi).toFixed(2)
                    ),
                fillColor: isNaN(AD[0]?.Dobi) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(AD[0]?.IV)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(AD[0]?.IV).toFixed(2)
                    ),
                fillColor: isNaN(AD[0]?.IV) ? '#dddddd' : '',
                style: 'tableContent',
              },

              {
                text: isNaN(AD[0]?.Temp)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(AD[0]?.Temp).toFixed(2)
                    ),
                fillColor: isNaN(AD[0]?.Temp) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(AD[0]?.OilQuantity)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(AD[0]?.OilQuantity).toFixed(2)
                    ),
                fillColor: isNaN(AD[0]?.OilQuantity) ? '#dddddd' : '',
                style: 'tableContent',
              },
            ],
            [
              { text: 'CPO ขาย', style: 'headerTable' },
              {
                text: isNaN(CPO[0]?.FFA)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPO[0]?.FFA).toFixed(2)
                    ),
                fillColor: isNaN(CPO[0]?.FFA) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(CPO[0]?.M)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPO[0]?.M).toFixed(2)
                    ),
                fillColor: isNaN(CPO[0]?.M) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(CPO[0]?.Dobi)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPO[0]?.Dobi).toFixed(2)
                    ),
                fillColor: isNaN(CPO[0]?.Dobi) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(CPO[0]?.IV)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPO[0]?.IV).toFixed(2)
                    ),
                fillColor: isNaN(CPO[0]?.IV) ? '#dddddd' : '',
                style: 'tableContent',
              },

              {
                text: isNaN(CPO[0]?.Temp)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPO[0]?.Temp).toFixed(2)
                    ),
                fillColor: isNaN(CPO[0]?.Temp) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(CPO[0]?.OilQuantity)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPO[0]?.OilQuantity).toFixed(2)
                    ),
                fillColor: isNaN(CPO[0]?.OilQuantity) ? '#dddddd' : '',
                style: 'tableContent',
              },
            ],
            [
              { text: 'CPKO ขาย', style: 'headerTable' },
              {
                text: isNaN(CPK[0]?.FFA)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPK[0]?.FFA).toFixed(2)
                    ),
                fillColor: isNaN(CPK[0]?.FFA) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(CPK[0]?.M)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPK[0]?.M).toFixed(2)
                    ),
                fillColor: isNaN(CPK[0]?.M) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(CPK[0]?.Dobi)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPK[0]?.Dobi).toFixed(2)
                    ),
                fillColor: isNaN(CPK[0]?.Dobi) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(CPK[0]?.IV)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPK[0]?.IV).toFixed(2)
                    ),
                fillColor: isNaN(CPK[0]?.IV) ? '#dddddd' : '',
                style: 'tableContent',
              },

              {
                text: isNaN(CPK[0]?.Temp)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPK[0]?.Temp).toFixed(2)
                    ),
                fillColor: isNaN(CPK[0]?.Temp) ? '#dddddd' : '',
                style: 'tableContent',
              },
              {
                text: isNaN(CPK[0]?.OilQuantity)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPK[0]?.OilQuantity).toFixed(2)
                    ),
                fillColor: isNaN(CPK[0]?.OilQuantity) ? '#dddddd' : '',
                style: 'tableContent',
              },
            ],
          ],
        },
      },
      {
        margin: [0, 5],
        table: {
          heights: [1],
          widths: ['18%', '14%', '14%', '54%'],
          body: [
            [
              { text: 'สัดส่วนปาล์มที่ใช้ผลิต', style: 'headerTable' },
              { text: 'จำนวนตัน', style: 'headerTable' },
              { text: 'คิดเป็น(%)', style: 'headerTable' },
              {
                text: 'หมายเหตุ:',
                alignment: 'left',
                style: 'headerTable',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: 'ปาล์มสวน',
                style: 'headerTable',
              },
              {
                text: isNaN(PalmSuan[0]?.Counting)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(PalmSuan[0]?.Counting).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: isNaN(PalmSuan[0]?.Percent)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(PalmSuan[0]?.Percent).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: PalmSuan[0]?.comment,
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: 'ปาล์มลานเท',
                style: 'headerTable',
              },
              {
                text: isNaN(Lantea[0]?.Counting)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(Lantea[0]?.Counting).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: isNaN(Lantea[0]?.Percent)
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(Lantea[0]?.Percent).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: Lantea[0]?.comment,

                style: 'headerTable',
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 5],
        table: {
          heights: [1],
          widths: ['10%', '10%', '13%', '13%', '15%', '39%'],
          body: [
            [
              {
                text: 'เช็คค่าสี CPKO วิเคราะห์เมื่อวันที่',
                style: 'headerTable',
                colSpan: 3,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: ` ${dayjs(date).format('D MMM BBBB')}`,
                style: 'headerTable',
                border: [false, true, false, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [false, true, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, true, true, false],
              },
            ],
            [
              { text: 'STD (CPKO)', style: 'headerTable' },
              { text: '9R', style: 'headerTable' },
              { text: '70Y', style: 'headerTable' },
              { text: '0B', style: 'headerTable' },
              { text: 'ผลการวิเคราะห์', style: 'headerTable' },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              { text: 'Process', style: 'headerTable' },
              {
                text: isNaN(ProcessCPKO[0]?.['9R'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(ProcessCPKO[0]?.['9R']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: isNaN(ProcessCPKO[0]?.['70Y'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(ProcessCPKO[0]?.['70Y']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: isNaN(ProcessCPKO[0]?.['0B'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(ProcessCPKO[0]?.['0B']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text:
                  ProcessCPKO[0]?.ResultProcess === 'ไม่ผ่าน'
                    ? 'ไม่ผ่าน'
                    : 'ผ่าน',
                style: 'tableContent',
                fillColor:
                  ProcessCPKO[0]?.ResultProcess === 'ไม่ผ่าน' ? colorsRed : '',
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: CPKO[1]?.STD === undefined ? 'P5' : CPKO[1]?.STD,
                style: 'headerTable',
              },
              {
                text: isNaN(CPKO[1]?.['9R'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPKO[1]?.['9R']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: isNaN(CPKO[1]?.['70Y'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPKO[1]?.['70Y']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: isNaN(CPKO[1]?.['0B'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(CPKO[1]?.['0B']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: CPKO[1]?.ResultProcess === 'ไม่ผ่าน' ? 'ไม่ผ่าน' : 'ผ่าน',
                style: 'tableContent',
                fillColor:
                  CPKO[1]?.ResultProcess === 'ไม่ผ่าน' ? colorsRed : '',
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              { text: 'STD (CPO)', style: 'headerTable' },
              { text: '22-25R', style: 'headerTable' },
              { text: '60-70Y', style: 'headerTable' },
              { text: '0B', style: 'headerTable' },
              { text: 'ผลการวิเคราะห์', style: 'headerTable' },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: PCPO[0]?.STD === undefined ? 'P1' : PCPO[0]?.STD,
                style: 'headerTable',
              },
              {
                text: isNaN(PCPO[0]?.['2225R'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(PCPO[0]?.['2225R']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: isNaN(PCPO[0]?.['6070Y'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(PCPO[0]?.['6070Y']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: isNaN(PCPO[0]?.['0B'])
                  ? '-'
                  : Intl.NumberFormat('en-US').format(
                      Number(PCPO[0]?.['0B']).toFixed(2)
                    ),
                style: 'tableContent',
              },
              {
                text: PCPO[0]?.ResultProcess,
                style: 'tableContent',
                fillColor:
                  PCPO[0]?.ResultProcess === 'ไม่ผ่าน' ? colorsRed : '',
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
    ],

    // images: {
    //   logo_imge: system?.logo,
    // },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 10,
      },
      header: {
        bold: true,
        fontSize: 15,
        margin: [0, 0, 0, 0],
      },
      header2: {
        fontSize: 16,
        bold: true,
      },
      headerTable: {
        margin: [-3, 0, -3, 0],
        fontSize: 13,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 14,
        margin: [-3, 0, -3, 0],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default LabYieldReport
