/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import React, { Component, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import dayjs from 'dayjs'

export default function VolumeChart({ valueFFB, valueCPO, valueYieldCPO }) {
  console.log('valueFFB', valueFFB)
  const [tickAmount, setTickAmount] = useState(valueFFB.length - 1)
  const [valueFormat, setValueFormat] = useState('DD MMM')
  const series = [
    {
      name: 'ปริมาณการผลิต FFB',
      type: 'column',
      data: valueFFB,
    },
    {
      name: 'ปริมาณการผลิต CPO',
      type: 'column',
      data: valueCPO,
    },

    {
      name: 'ปริมาณ Yield ที่ได้',
      type: 'line',
      data: valueYieldCPO,
    },
  ]
  const options = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 4],
    },
    markers: {
      size: 5,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => dayjs(value).locale('th').format(valueFormat),
      },
      tickAmount,
    },
    yaxis: [
      {
        //seriesName: 'Column A',
        title: {
          text: 'ปริมาณการผลิต FFB (ton/d)',
          style: {
            colors: '#259FFC',
          },
        },

        axisBorder: {
          show: true,
          color: '#259FFC',
        },
        labels: {
          formatter: function (val) {
            return val
          },
          style: {
            colors: '#259FFC',
          },
        },
      },

      {
        //seriesName: 'Income',
        title: {
          text: 'ปริมาณการผลิต CPO (ton/d)',
          style: {
            color: '#25E6A6',
          },
        },
        axisBorder: {
          show: true,
          color: '#25E6A6',
        },
        labels: {
          formatter: function (val) {
            return val
          },
          style: {
            colors: '#25E6A6',
          },
        },
      },
      {
        //seriesName: 'Line C',
        opposite: true,
        title: {
          text: 'Yield ที่ได้ (เปอร์เซ็นต์)',
          style: {
            color: '#FEB019',
          },
        },
        axisBorder: {
          show: true,
          color: '#FEB019',
        },
        labels: {
          formatter: function (val) {
            return val
          },

          style: {
            colors: '#FEB019',
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  )
}
