import React, { Component, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import dayjs from 'dayjs'

export default function LossChart({
  valueFiberLoss,
  valueDecanterLoss,
  valueWasteLoss,
}) {
  console.log('fiber ', valueDecanterLoss)
  const [tickAmount, setTickAmount] = useState(valueDecanterLoss?.length - 1)
  const [valueFormat, setValueFormat] = useState('DD MMM')
  const series = [
    {
      name: 'ปริมาณการสูญเสียน้ำมันจากเส้นใย',
      data: valueFiberLoss,
    },
    // {
    //   name: 'ปริมาณการสูญเสียน้ำมันจากทะลายปาล์มเปล่า',
    //   data: [0, 0],
    // },
    {
      name: 'ปริมาณการสูญเสียน้ำมันใน DECANTER CAKE',
      data: valueDecanterLoss,
    },
    {
      name: 'ปริมาณการสูญเสียน้ำมันในน้ำเสีย',
      data: valueWasteLoss,
    },
  ]
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
            formatter: (val) => {
              return val.toFixed(2)
            },
          },
        },
      },
    },
    // xaxis: {
    //   type: 'datetime',
    //   categories: [
    //     '01/01/2011 GMT',
    //     '01/02/2011 GMT',
    //     '01/03/2011 GMT',
    //     '01/04/2011 GMT',
    //   ],
    // },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => dayjs(value).locale('th').format(valueFormat),
      },
      tickAmount,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val
        },
      },
    },
    //   tickAmount,
    //   //min: graphMin,
    //   //max: graphMax,
    // },
    // legend: {
    //   horizontalAlign: 'left',
    //   offsetX: 40,
    // },
    fill: {
      opacity: 1,
    },
  }

  return (
    <div className="app">
      <div className="row">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  )
}
