import React from 'react'
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  HStack,
  Box,
  Badge,
  Stack,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { Link } from 'react-router-dom'
import exportFormJSON from 'export-from-json'
import { useTranslation } from 'react-i18next'

export default function StationList({ stationArray }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDeleteStation = (stationId) => {
    const confirm = window.confirm('ยืนยันการลบสถานี')
    if (confirm) {
      dispatch(actions.stationDelete(stationId)).then(() => {
        dispatch(actions.stationAll())
      })
    }
  }

  const handleExportJSON = (eachStation) => {
    const jsonData = exportFormJSON({
      data: {
        template: {
          ...eachStation,
          _id: undefined,
          templateName: `${eachStation?.name}-Template`,
          name: undefined,
        },
      },
      fileName: `${eachStation?.name}-Template`,
      exportType: 'json',
    })
    console.log('JSON Data', jsonData)
  }

  return _.map(stationArray, (station, index) => (
    <Box borderRadius="lg" padding="4" className=" my-2 overflow-auto bg-white">
      <div className="w-full">
        <div className="flex flex-wrap gap-3 ">
          <div className="w-3/5 ">
            <h5 className="text-lg font-bold font-sans">
              {' '}
              {station.name}{' '}
              <Badge colorScheme="teal" size="sm">
                TOPIC : {station.externalRef}{' '}
              </Badge>{' '}
            </h5>
            <h4 className="text-base font-sans">{station.location}</h4>
          </div>
          <div className="flex justify-start w-2/5 ">
            <Stack spacing={4} direction="row" align="center">
              <Link to={'/system/stations/' + station._id}>
                <Button colorScheme="yellow" size="sm">
                  {t('utility.edit')}
                </Button>
              </Link>
              <Button
                colorScheme="blue"
                size="sm"
                onClick={() => handleExportJSON(station)}
              >
                Export
              </Button>
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => handleDeleteStation(station._id)}
              >
                {t('utility.delete')}
              </Button>
            </Stack>
          </div>
        </div>
        <div className="flex flex-wrap h-20 my-3 ">
          <div className="text-base font-sans font-bold my-2">จัดการสถานี</div>
          <div className="flex flex-wrap gap-2  w-full">
            <Link to={'/system/stations/sensors/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                {t('setting.sensor')}
              </Button>
            </Link>
            <Link to={'/system/stations/variables/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                ตัวแปร
              </Button>
            </Link>
            <Link to={'/system/stations/equations/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                สมการ
              </Button>
            </Link>
            {/* <Link to={'/system/stations/predictions/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                การทำนาย
              </Button>
            </Link> */}
            <Link to={'/system/stations/gauge/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                เกจ
              </Button>
            </Link>
            <Link to={'/system/stations/arrange/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                จัดเรียงเกจ
              </Button>
            </Link>
            <Link to={'/system/stations/charts/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                กราฟ
              </Button>
            </Link>
            <Link to={'/system/stations/diagram/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                ไดอะแกรม
              </Button>
            </Link>
            <Link to={'/system/stations/charts/arrange/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                จัดเรียงกราฟ
              </Button>
            </Link>
            <Link to={'/system/stations/diagram/arrange/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                จัดเรียงไดอะแกรม
              </Button>
            </Link>
            <Link to={'/system/stations/form/management/' + station._id}>
              <Button colorScheme="blue" size="sm" variant="outline">
                จัดการแบบฟอร์ม
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Box>
  ))
}
