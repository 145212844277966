export const FilterType = {
  Filter: {
    value: 'FilterType',
    describe: 'Filter',
    image: 'Filter.svg',
  },

  FilterSrc: [
    {
      name: 'Filter',
      value: 'Filter.svg',
    },
  ],
}
