import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'
//import PredictionComponent from '../../../components/Prediction/PredictionMakingComponent'
import { useParams, useHistory, Link } from 'react-router-dom'
import * as actions from '../../../redux/actions'

export default function ManagementSummarize() {
  const { t } = useTranslation()
  const { register, handleSubmit, reset } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const allPrediction = useSelector((state) => state.prediction)
  const dispatch = useDispatch()

  useEffect(() => {
    // console.log('Call Get All Prediction')
    dispatch(actions.predictionAll())
      .then(() => {})
      .catch((err) => {
        alert(`Error ${err?.message}`)
      })
    return () => {}
  }, [])
  useEffect(() => {
    dispatch(actions.stationAll()).then(() => {})
    return () => {}
  }, [])

  const findStation = (id) => {
    const data = _?.find(allStations?.arr, (each) => each?._id === id)
    return data
  }
  //   const genPredictionList = () => {
  //     return _.map(allPrediction?.arr, (predictions, index) => {
  //       return (
  //         <div>
  //           <PredictionComponent
  //             key={index}
  //             predictions={predictions}
  //             stations={findStation(predictions.station)}
  //             allStation={allStations}
  //             placeToEdit="station"
  //           />
  //         </div>
  //       )
  //     })
  //   }

  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                {t('setting.systemManagement')}
              </h6>
              <h2 className="text-gray-800 text-2xl font-bold font-sans">
                จัดการรูปแบบสรุปและการทำนาย
              </h2>
            </div>
          </div>
        </div>
        <div className="my-4  px-4">
          <Link to={'/system/summarizeconfig'}>
            <div className="bg-blue-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
              <i className="fas fa-th-list text-4xl text-white "></i>
              <p className="text-white mt-4 ">รูปแบบสรุป</p>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4  ">
          <Link to={'/system/prediction'}>
            <div className="bg-green-800 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
              <i className="fas fa-poll text-4xl text-white "></i>
              <p className="text-white mt-4 ">รูปแบบการทำนาย</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
