/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import * as actions from '../../../redux/actions'
import SensorComponent from '../../../components/Sensors/NotifySensorComponent'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

// components

import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { Button } from '@chakra-ui/button'

export default function ManagementNotify() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()
  const dispatch = useDispatch()
  const station = useSelector((state) => state.station)
  useEffect(() => {
    dispatch(actions.stationGet(id)).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [id])

  const genSensorList = () => {
    return _.map(station.sensors, (sensor, index) => (
      <div key={index}>
        <SensorComponent
          key={index}
          sensorData={sensor}
          stationData={station}
          placeToEdit="station"
        />
      </div>
    ))
  }

  return isLoading && station ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('setting.systemManagement')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {t('setting.notificationManagement')} {station.name}
            </h2>
            <h5 className="text-base text-gray-700 font-sans">
              {station.description} | {station.location}{' '}
            </h5>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <Link to={'/notify'}>
            <Button variant="outline" size="sm">
              {t('counter.back')}
            </Button>
          </Link>
        </div>
      </div>
      <div className="px-4 w-full">{genSensorList()}</div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
