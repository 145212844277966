/* eslint-disable no-plusplus */
import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import cos from './cos.json'
import delta from './delta.json'
import { red } from '@mui/material/colors'
import { faL } from '@fortawesome/free-solid-svg-icons'
import RenderBG004_Page2 from './RenderBG004_Page2'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}
const renderFormTable = (title, unit, eachData) => {
  return [
    {
      text: title,
      colSpan: 3,
      alignment: 'left',
      style: 'headerTable',
      margin: [2, 0],
    },
    {},
    {},
    unit,
    {
      text:
        eachData?.['00.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['00.00'])
          : '-', //00.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['01.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['01.00'])
          : '-', //01.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['02.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['02.00'])
          : '-', //02.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['03.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['03.00'])
          : '-', //03.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['04.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['04.00'])
          : '-', //04.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['05.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['05.00'])
          : '-', //05.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['06.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['06.00'])
          : '-', //06.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['07.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['07.00'])
          : '-', //07.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['08.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['08.00'])
          : '-', //08.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['09.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['09.00'])
          : '-', //09.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['10.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['10.00'])
          : '-', //10.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
    {
      text:
        eachData?.['11.00'] !== undefined && eachData?.['03.00'] !== ''
          ? Intl.NumberFormat('en-US').format(eachData?.['11.00'])
          : '-', //11.00
      style: 'headerTable',
      colSpan: 2,
    },
    {},
  ]
}

const renderCylinderTable = (i1, i2, eachData) => {
  console.log('ggg', i2)
  return [
    {
      text: [
        { text: 'Exhaust gas\n' },
        { text: 'temperature\n' },
        { text: 'Cylinder on. : \n\n\n\n' },
        { text: '500 - 630  ํC\n' },
      ],
      rowSpan: 10,
      alignment: 'left',
      style: 'headerTable',
      margin: [2, 0],
    },
    {
      text: i1, //00.00
      style: 'headerTable',
    },
    {
      text: i2, //00.00
      style: 'headerTable',
    },
    { text: ' ํC', style: 'headerTable' },
    {
      text: eachData?.['00.00']?.[i1] || '-', //00.00
      style: 'headerTable',
    },
    {
      text: eachData?.['00.00']?.[i2] || '-', //00.00
      style: 'headerTable',
    },

    {
      text: eachData?.['01.00']?.[i1] || '-', //01.00
      style: 'headerTable',
    },
    {
      text: eachData?.['01.00']?.[i2] || '-', //01.00
      style: 'headerTable',
    },

    {
      text: eachData?.['02.00']?.[i1] || '-', //02.00
      style: 'headerTable',
    },
    {
      text: eachData?.['02.00']?.[i2] || '-', //02.00
      style: 'headerTable',
    },

    {
      text: eachData?.['03.00']?.[i1] || '-', //03.00
      style: 'headerTable',
    },
    {
      text: eachData?.['03.00']?.[i2] || '-', //03.00
      style: 'headerTable',
    },

    {
      text: eachData?.['04.00']?.[i1] || '-', //04.00
      style: 'headerTable',
    },
    {
      text: eachData?.['04.00']?.[i2] || '-', //04.00
      style: 'headerTable',
    },
    {
      text: eachData?.['05.00']?.[i1] || '-', //05.00
      style: 'headerTable',
    },
    {
      text: eachData?.['05.00']?.[i2] || '-', //05.00
      style: 'headerTable',
    },

    {
      text: eachData?.['06.00']?.[i1] || '-', //06.00
      style: 'headerTable',
    },
    {
      text: eachData?.['06.00']?.[i2] || '-', //06.00
      style: 'headerTable',
    },

    {
      text: eachData?.['07.00']?.[i1] || '-', //07.00
      style: 'headerTable',
    },
    {
      text: eachData?.['07.00']?.[i2] || '-', //07.00
      style: 'headerTable',
    },

    {
      text: eachData?.['08.00']?.[i1] || '-', //08.00
      style: 'headerTable',
    },
    {
      text: eachData?.['08.00']?.[i2] || '-', //08.00
      style: 'headerTable',
    },

    {
      text: eachData?.['09.00']?.[i1] || '-', //09.00
      style: 'headerTable',
    },
    {
      text: eachData?.['09.00']?.[i2] || '-', //09.00
      style: 'headerTable',
    },

    {
      text: eachData?.['10.00']?.[i1] || '-', //10.00
      style: 'headerTable',
    },
    {
      text: eachData?.['10.00']?.[i2] || '-', //10.00
      style: 'headerTable',
    },

    {
      text: eachData?.['11.00']?.[i1] || '-', //11.00
      style: 'headerTable',
    },
    {
      text: eachData?.['11.00']?.[i2] || '-', //11.00
      style: 'headerTable',
    },
  ]
}

const renderCheckContRows = (data) => {
  const content = []

  if (data?.length === 0) {
    content.push(
      [
        {
          text: 'Electrical power P',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'kW',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Electrical reactive power Q',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'kVAr',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Electrical apparent Power',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'kVA',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Cos',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          image: cos.image,
          fit: [7, 7],
          alignment: 'left',
          margin: [5, 2, 0, 2],
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Current Average Im',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'A',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Voltage Average Um',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'V',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Neutral Conductor In',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          image: delta.image,
          fit: [7, 7],
          alignment: 'left',
          margin: [5, 2, 0, 2],
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Excitation Voltage Ue',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'V',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Active energy EP',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'MWh',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Reactive energy EP',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'MVARH',
          style: 'headerTable',
          fontSize: 9,
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Exhaust gas',
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
          border: [true, true, true, false],
        },
        { text: '1', style: 'headerTable' },
        { text: '11', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: 'temperature',
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
          border: [true, false, true, false],
        },
        { text: '2', style: 'headerTable' },
        { text: '12', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: 'Cylinder on. : ',
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
          border: [true, false, true, false],
        },
        { text: '3', style: 'headerTable' },
        { text: '13', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: '',
          alignment: 'left',
          border: [true, false, true, false],
        },
        { text: '4', style: 'headerTable' },
        { text: '14', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: '500 - 630  ํC',
          alignment: 'center',
          style: 'headerTable',
          margin: [2, 0],
          border: [true, false, true, false],
        },
        { text: '5', style: 'headerTable' },
        { text: '15', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: '',
          border: [true, false, true, false],
        },
        { text: '6', style: 'headerTable' },
        { text: '16', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: '',
          border: [true, false, true, false],
        },
        { text: '7', style: 'headerTable' },
        { text: '17', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: '',
          border: [true, false, true, false],
        },
        { text: '8', style: 'headerTable' },
        { text: '18', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: '',
          border: [true, false, true, false],
        },
        { text: '9', style: 'headerTable' },
        { text: '19', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: '',

          border: [true, false, true, true],
        },
        { text: '10', style: 'headerTable' },
        { text: '20', style: 'headerTable' },
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      [
        {
          text: 'Generator bearing DE temperature',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Generator bearing NDE temperature',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Jacket water temperature',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Jacket water pressure',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' bar',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Engine oil temperature',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Oil pressure',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' bar',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Charge temperature',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Boot pressure',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' bar',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Fuel gas volume controller setting',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: '%',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Throttle valve',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: '%',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Compressor bypass position',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: '%',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Operation hours',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: 'h',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Start counter',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: '',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ],
      [
        {
          text: 'Gas temperature',
          colSpan: 3,
          alignment: 'left',
          style: 'headerTable',
          margin: [2, 0],
        },
        {},
        {},
        {
          text: ' ํC',
          style: 'headerTable',
        },
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
        {
          text: '',
          style: 'headerTable',
          colSpan: 2,
        },
        {},
      ]
    )
    return content
  } else {
    _.map(data, (eachData, index) => {
      if (eachData?.name === 'EPP') {
        const unit = {
          text: 'kW',
          style: 'headerTable',
        }
        content.push(renderFormTable('Electrical power P', unit, eachData))
      }
      if (eachData?.name === 'ERPQ') {
        const unit = {
          text: 'kVAr',
          style: 'headerTable',
        }
        content.push(
          renderFormTable('Electrical reactive power Q', unit, eachData)
        )
      }
      if (eachData?.name === 'EAP') {
        const unit = {
          text: 'kVA',
          style: 'headerTable',
        }
        content.push(
          renderFormTable('Electrical apparent power', unit, eachData)
        )
      }
      if (eachData?.name === 'Cos') {
        const unit = {
          image: cos.image,
          fit: [7, 7],
          alignment: 'left',
          margin: [5, 2, 0, 2],
          style: 'headerTable',
        }
        content.push(renderFormTable('Cos', unit, eachData)) //แก้หน่วย
      }
      if (eachData?.name === 'CurrentAIm') {
        const unit = {
          text: 'A',
          style: 'headerTable',
        }
        content.push(renderFormTable('Current Average Im', unit, eachData))
      }
      if (eachData?.name === 'VoltageAUm') {
        const unit = {
          text: 'V',
          style: 'headerTable',
        }
        content.push(renderFormTable('Voltage Average Um', unit, eachData))
      }
      if (eachData?.name === 'NeutralCIn') {
        const unit = {
          image: delta.image,
          fit: [7, 7],
          alignment: 'left',
          margin: [5, 2, 0, 2],
          style: 'headerTable',
        }
        content.push(renderFormTable('Neutral Conductor In', unit, eachData))
      }
      if (eachData?.name === 'ExVUe') {
        const unit = {
          text: 'V',
          style: 'headerTable',
        }
        content.push(renderFormTable('Excitation Voltage Ue', unit, eachData))
      }
      if (eachData?.name === 'ActiveEEP') {
        const unit = {
          text: 'MWh',
          style: 'headerTable',
        }
        content.push(renderFormTable('Active energy EP', unit, eachData))
      }
      if (eachData?.name === 'ReEEP') {
        const unit = {
          text: 'MVARH',
          fontSize: 9,
          style: 'headerTable',
        }
        content.push(renderFormTable('Reactive energy EP', unit, eachData))
      }

      if (eachData?.name === 'CylinderOn') {
        for (let i = 0; i < 10; i++) {
          content.push(renderCylinderTable(i + 1, i + 11, eachData))
        }
      }
      if (eachData?.name === 'GenDE') {
        const unit = {
          text: ' ํC',
          style: 'headerTable',
        }
        content.push(
          renderFormTable('Generator bearing DE temperature', unit, eachData)
        )
      }
      if (eachData?.name === 'GenNDE') {
        const unit = {
          text: ' ํC',
          style: 'headerTable',
        }
        content.push(
          renderFormTable('Generator bearing NDE temperature', unit, eachData)
        )
      }
      if (eachData?.name === 'JacketWaterTemp') {
        const unit = {
          text: ' ํC',
          style: 'headerTable',
        }
        content.push(
          renderFormTable('Jacket water temperature', unit, eachData)
        )
      }
      if (eachData?.name === 'JacketWaterPress') {
        const unit = {
          text: 'bar',
          style: 'headerTable',
        }
        content.push(renderFormTable('Jacket water pressure', unit, eachData))
      }
      if (eachData?.name === 'EngineOilTemp') {
        const unit = {
          text: ' ํC',
          style: 'headerTable',
        }
        content.push(renderFormTable('Engine oil temperature', unit, eachData))
      }
      if (eachData?.name === 'OilPress') {
        const unit = {
          text: 'bar',
          style: 'headerTable',
        }
        content.push(renderFormTable('Oil pressure', unit, eachData))
      }
      if (eachData?.name === 'ChargeTemp') {
        const unit = {
          text: ' ํC',
          style: 'headerTable',
        }
        content.push(renderFormTable('Charge temperature', unit, eachData))
      }
      if (eachData?.name === 'BootPress') {
        const unit = {
          text: 'bar',
          style: 'headerTable',
        }
        content.push(renderFormTable('Boot pressure', unit, eachData))
      }
      if (eachData?.name === 'FuelControl') {
        const unit = {
          text: '%',
          style: 'headerTable',
        }
        content.push(
          renderFormTable('Fuel gas volume controller setting', unit, eachData)
        )
      }
      if (eachData?.name === 'ThrottleValve') {
        const unit = {
          text: '%',
          style: 'headerTable',
        }
        content.push(renderFormTable('Throttle valve', unit, eachData))
      }
      if (eachData?.name === 'ComBypass') {
        const unit = {
          text: '%',
          style: 'headerTable',
        }
        content.push(
          renderFormTable('Compressor bypass position', unit, eachData)
        )
      }
      if (eachData?.name === 'OperateH') {
        const unit = {
          text: 'h',
          style: 'headerTable',
        }
        content.push(renderFormTable('Operation hours', unit, eachData))
      }
      if (eachData?.name === 'StartCounter') {
        const unit = {
          text: ' ',
          style: 'headerTable',
        }
        content.push(renderFormTable('Start counter', unit, eachData))
      }
      if (eachData?.name === 'GasTemp') {
        const unit = {
          text: ' ํC',
          style: 'headerTable',
        }
        content.push(renderFormTable('Gas temperature', unit, eachData))
      }
    })
  }

  return content
}

const renderFooter = (data) => {
  const content = []
  if (data?.length === 0) {
    content.push(
      [
        {
          text: '............................................................................................................................................................................................................................................................................................',
          alignment: 'center',
          colSpan: 2,
          border: [false, false, false, false],
        },
        {},
      ],
      [
        {
          text: '............................................................................................................................................................................................................................................................................................',
          alignment: 'center',
          colSpan: 2,
          border: [false, false, false, false],
        },
        {},
      ],
      [
        {
          text: '............................................................................................................................................................................................................................................................................................',
          alignment: 'center',
          colSpan: 2,
          border: [false, false, false, false],
        },
        {},
      ],
      [
        {
          text: '............................................................................................................................................................................................................................................................................................',
          alignment: 'center',
          colSpan: 2,
          border: [false, false, false, false],
        },
        {},
      ]
    )
    return content
  } else {
    content.push(
      [
        {
          text: data[0]?.comment,
          alignment: 'left',
          colSpan: 2,
          border: [false, false, false, false],
        },
        {},
      ],
      [
        {
          text: '............................................................................................................................................................................................................................................................................................',
          alignment: 'center',
          colSpan: 2,
          border: [false, false, false, false],
        },
        {},
      ],
      [
        {
          text: '............................................................................................................................................................................................................................................................................................',
          alignment: 'center',
          colSpan: 2,
          border: [false, false, false, false],
        },
        {},
      ],
      [
        {
          text: '............................................................................................................................................................................................................................................................................................',
          alignment: 'center',
          colSpan: 2,
          border: [false, false, false, false],
        },
        {},
      ]
    )
    return content
  }
}

const FM_BG_004 = async (form, formInput, subForm, system, date) => {
  const FComment = _.filter(formInput?.arr, (e) => e.comment !== '')
  const FooterC = renderFooter(FComment)

  const reducedRows = _.reduce(
    formInput?.arr,
    (result, value, key) => {
      const resultOnEachRound = []
      _.map(value, (eachValue, index) => {
        const newObject = {
          name: index,
        }

        newObject[value?.Time] = eachValue
        resultOnEachRound.push(newObject)
      })

      return _.merge(result, resultOnEachRound)
    },
    []
  )

  console.log('Reduce Row', reducedRows)

  const TableBody = renderCheckContRows(reducedRows)

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [10, 5, 10, 5],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },
    header: [],

    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        //margin: [10, 10, 10, 0],
        style: 'tableExample',
        table: {
          widths: ['20%', '50%', '15%', '15%'],
          body: [
            [
              {
                image: 'logo_imge',
                rowSpan: 2,
                fit: [27, 27],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: `${form?.name || ''} - (FM-BG-004)`,
                alignment: 'center',
                bold: true,
                rowSpan: 2,
                font: 'AngsanaNew',
                fontSize: '15',
                border: [true, true, true, false],
                margin: [59, 0],
              },
              {
                text: `เอกสารหมายเลข: ${form?.docNo || '-'}  `,
                style: 'header',
                colSpan: 2,
                margin: [0, 0, 0, 0],
              },
              {},
            ],
            [
              { text: '', alignment: 'center' },
              {},
              {
                text: 'แก้ไขครั้งที่: 00',
                style: 'header',
                margin: [0, 2, 0, -2],
              },
              {
                text: 'หน้าที่ : 1/2',
                style: 'header',
                margin: [0, 2, 0, -2],
              },
            ],
            [
              {
                text: 'THAITALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                bold: true,
                font: 'Impac',
                fontSize: '9',
                border: [true, false, true, true],
              },
              {
                text: `Date ${dayjs(date).format('D / MMMM / BBBB ')}`,
                style: 'header',
                border: [true, false, true, true],
                margin: [10, 0, 10, 0],
              },

              {
                text: `วันที่บังคับใช้: ${
                  dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                }`,
                style: 'header',
                colSpan: 2,
                margin: [0, 2, 0, -2],
              },
              {},
            ],
          ],
        },
      },
      {
        table: {
          widths: [
            '15%',
            '2.5%',
            '2.5%',
            '5%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
            '3.125%',
          ],
          body: [
            [
              // Row 1
              {
                text: 'Operational data to be read',
                colSpan: 3,
                style: 'headerTable',
              },
              {},
              {},
              {
                text: 'Unit',
                style: 'headerTable',
              },
              {
                text: '00.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '01.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '02.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '03.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '04.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '05.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '06.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '07.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '08.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '09.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '10.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
              {
                text: '11.00',
                style: 'headerTable',
                colSpan: 2,
              },
              {},
            ],

            ...TableBody,
          ],
        },
      },
      {
        alignment: 'between',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'หมายเหตุ',
                fontSize: 12,
                alignment: 'left',
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            ...FooterC,
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '.......................................................',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'หน.แผนกก๊าซชีวภาพ',
                alignment: 'center',
                fontSize: 12,
                bold: true,
                border: [false, false, false, false],
              },
            ],
          ],
        },
        styles: {
          header: {
            fontSize: 8,
            bold: true,
            alignment: 'right',
          },
          header1: {
            fontSize: 12,
            bold: true,
          },
        },
      },
      {
        text: '',
        pageBreak: 'before',
        pageOrientation: 'portrait',
      },
      RenderBG004_Page2(form, formInput, subForm, system, date),
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: {
        font: 'FontAwesome',
        bold: true,
        alignment: 'center',
        fontSize: 7,
      },
      header: {
        bold: true,
        fontSize: 12,
        alignment: 'center',
        margin: [0, 0, 0, 0],
      },
      header2: {
        fontSize: 14,
      },
      headerTable: {
        margin: [-5, 0, -5, 0],
        fontSize: 10,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 10,
        margin: [0, 0, 0, 0],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BG_004
