import React, { useState, useEffect } from 'react'
import {
  Button,
  Link as LinkUI,
  Box,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import * as actions from '../../redux/actions/'
import _, { find } from 'lodash'
import dayjs from 'dayjs'
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp'
import { useTranslation } from 'react-i18next'

export default function BiogasDetail() {
  const params = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  //const [isLoading, setIsLoading] = useState(false)
  const summarizes = useSelector((state) => state.summarize)
  const stations = useSelector((state) => state.station)
  const [isLoading, setIsLoading] = useState(false)
  const [startTime, setStartTime] = useState(dayjs().format('YYYY-MM-DD'))
  const [endTime, setEndTime] = useState(
    dayjs(startTime).subtract(1, 'month').format('YYYY-MM-DD')
  )
  const formInput = useSelector((state) => state.formInput)

  useEffect(() => {
    dispatch(actions.summarizeAll())
    dispatch(actions.stationAll())
    dispatch(actions.formInputSummarize(endTime, startTime)).then(() =>
      setIsLoading(true)
    )
    return () => {}
  }, [params])

  const genMeter = _.map(formInput?.arr, (fm) => {
    return fm?.[2]
  })

  const findStation = (id) => {
    const data = _?.find(stations?.arr, (each) => each?._id === id)
    return data
  }

  //console.log('gen', genMeter, genMeter[2]?.GenMeter)
  const findData = (id) => {
    const varStation = findStation('63c668b1ad97c655441b7f88')
    if (id === '65b0ca8f9985d6174c098c9e') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '65b0cc749985d6174c098de5') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else {
      return null
    }
    //console.log('selectvariable', data)
    //console.log('station?.variable', station?.variables)
    //setSelectVariable(data)
  }

  const displayAlert = (name) => {
    if (name === 'ปริมาณหน่วยไฟฟ้าสะสม') {
      if (
        genMeter[2]?.length !== 0 &&
        parseFloat(genMeter[2]?.GenMeter?.['Total 1,2']).toFixed(2) <= 48000
      ) {
        return (
          <StatNumber textColor="blue.400">
            {Intl.NumberFormat('en-US').format(
              parseFloat(genMeter[2]?.GenMeter?.['Total 1,2']).toFixed(2)
            )}

            {' หน่วย/วัน'}
          </StatNumber>
        )
      } else if (
        genMeter[2]?.length === 0 &&
        parseFloat(findData('65b0ca8f9985d6174c098c9e')?.numericStatus) <= 48000
      ) {
        return (
          <StatNumber textColor="blue.400">
            {parseFloat(
              findData('65b0ca8f9985d6174c098c9e')?.numericStatus
            ).toFixed(2)}{' '}
            {' หน่วย/วัน'}
          </StatNumber>
        )
      } else if (genMeter[2]?.length !== 0) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {Intl.NumberFormat('en-US').format(
                parseFloat(genMeter[2]?.GenMeter?.['Total 1,2']).toFixed(2)
              )}

              {' หน่วย/วัน'}
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {parseFloat(genMeter[2]?.GenMeter?.['Total 1,2']).toFixed(2) -
                48000}
              {' หน่วย/วัน'}
            </StatLabel>
          </Stat>
        )
      } else if (genMeter[2]?.length === 0) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {parseFloat(
                findData('65b0ca8f9985d6174c098c9e')?.numericStatus
              ).toFixed(2)}{' '}
              {' หน่วย/วัน'}
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {parseFloat(findData('65b0ca8f9985d6174c098c9e')?.numericStatus) -
                48000}
              {' หน่วย/วัน'}
            </StatLabel>
          </Stat>
        )
      } else {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {'N/A'} {' หน่วย/วัน'}
            </StatNumber>
          </Stat>
        )
      }
    } else if (name === 'ปริมาณแก๊สที่ใช้ผลิตไฟฟ้า') {
      if (
        genMeter[2]?.length !== 0 &&
        parseFloat(genMeter[2]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(2) <=
          24000
      ) {
        return (
          <StatNumber textColor="blue.400">
            {Intl.NumberFormat('en-US').format(
              parseFloat(genMeter[2]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(
                2
              )
            )}

            {' Nm'}
            <sup>{'3'}</sup>
          </StatNumber>
        )
      } else if (
        genMeter[2]?.length === 0 &&
        parseFloat(
          findData('65b0cc749985d6174c098de5')?.numericStatus.toFixed(2)
        ) <= 24000
      ) {
        return (
          <StatNumber textColor="blue.400">
            {parseFloat(
              findData('65b0cc749985d6174c098de5')?.numericStatus
            ).toFixed(2)}{' '}
            {' Nm'}
            <sup>{'3'}</sup>
          </StatNumber>
        )
      } else if (genMeter[2]?.length !== 0) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {Intl.NumberFormat('en-US').format(
                parseFloat(
                  genMeter[2]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต
                ).toFixed(2)
              )}

              {' Nm'}
              <sup>{'3'}</sup>
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {parseFloat(genMeter[2]?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(
                2
              ) - 24000}
              {' Nm'}
              <sup>{'3'}</sup>
            </StatLabel>
          </Stat>
        )
      } else if (genMeter[2]?.length === 0) {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {parseFloat(
                findData('65b0cc749985d6174c098de5')?.numericStatus
              ).toFixed(2)}{' '}
              {' Nm'}
              <sup>{'3'}</sup>
            </StatNumber>
            <StatLabel>
              <ArrowDropUpSharpIcon /> {'สูงกว่ากำหนด '}
              {parseFloat(findData('65b0cc749985d6174c098de5')?.numericStatus) -
                24000}
              {' Nm'}
              <sup>{'3'}</sup>
            </StatLabel>
          </Stat>
        )
      } else {
        return (
          <Stat>
            <StatNumber textColor="yellow.400">
              {'N/A'} {' Nm'}
              <sup>{'3'}</sup>
            </StatNumber>
          </Stat>
        )
      }
    }
  }

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded ">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              การสรุปผลการดำเนินงาน
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              รายการสรุป Biogas{' '}
            </h2>
          </div>
        </div>

        <div className="flex justify-between my-2">
          <Link to={'/summarize'}>
            <Button variant="outline" size="sm">
              กลับ
            </Button>
          </Link>
        </div>

        <div className="flex flex-wrap p-4 w-full bg-gray-200 rounded-sm">
          <div className="w-full">
            <div className="my-2 flex justify-between"></div>
            <div className="mb-10 flex justify-center gap-1 grid-cols-2">
              <Box
                // w={[60, '2xl']}
                // h="150px"
                borderWidth="3px"
                borderRadius="lg"
                className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25 "
              >
                <Stat>
                  <StatLabel fontSize={['sm', 'lg']}>
                    <span className="flex justify-center font-bold ">
                      {'ปริมาณหน่วยไฟฟ้าสะสม'}
                    </span>
                  </StatLabel>
                  {displayAlert('ปริมาณหน่วยไฟฟ้าสะสม')}

                  <StatHelpText>
                    {genMeter[2]?.length === 0
                      ? dayjs(findData('65b0ca8f9985d6174c098c9e')?.lastDate)
                          .locale('th')
                          .format('DD MMM BBBB')
                      : dayjs(genMeter[2]?.date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                  </StatHelpText>
                </Stat>
              </Box>
              <Box
                // w={[60, '2xl']}
                // h="150px"
                borderWidth="3px"
                borderRadius="lg"
                className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25 "
              >
                <Stat>
                  <StatLabel fontSize={['sm', 'lg']}>
                    <span className="flex justify-center font-bold ">
                      {'ปริมาณแก๊สที่ใช้ผลิตไฟฟ้า'}
                    </span>
                  </StatLabel>
                  {displayAlert('ปริมาณแก๊สที่ใช้ผลิตไฟฟ้า')}

                  <StatHelpText>
                    {genMeter[2]?.length === 0
                      ? dayjs(findData('65b0cc749985d6174c098de5')?.lastDate)
                          .locale('th')
                          .format('DD MMM BBBB')
                      : dayjs(genMeter[2]?.date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                  </StatHelpText>
                </Stat>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
