import {
  LOG_ALL,
  LOG_GET,
  LOG_PUT,
  LOG_DEL,
  LOG_POST,
} from '../../actions/types'

const initialState = {
  log: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case LOG_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      }
    case LOG_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case LOG_POST:
      return action.payload
    case LOG_PUT:
      return action.payload
    case LOG_DEL:
      return { isLoading: true }
    default:
      return state
  }
}
