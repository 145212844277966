import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Button,
  Link as LinkUI,
  Box,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
//import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';

import DisplayValueAlert from './DisplayValueAlert'
import DataFormInput from 'views/summarize/component/DataFormInput'

export default function DisplayDetail({
  oilLossAll,
  yieldAll,
  oilLoss,
  ffb,
  cpo,
  yieldCpo,
  fiberLoss,
  decanterLoss,
  wasteLoss,
}) {
  const calOilLoss =
    (fiberLoss?.numericStatus * 751.879699) / ffb +
    (decanterLoss?.numericStatus * 2857.142857) / ffb +
    (wasteLoss?.numericStatus * 200) / ffb

  return (
    <div className="w-full">
      <div className="my-2 flex justify-between">
        <h2 className="flex xl:text-3xl">{''}</h2>
        <span className="font-sans  text-gray-600 ml-10 lg:text-lg">
          {' ข้อมูลล่าสุดเมื่อวันที่ : '}
          <i className="font-bold font-sans  text-gray-800">
            {oilLossAll[0]?.length === 0
              ? dayjs(oilLoss?.lastDate).locale('th').format('DD MMM BBBB')
              : dayjs(oilLossAll[0]?.date).locale('th').format('DD MMM BBBB')}
          </i>
        </span>
      </div>
      <div className="my-2 mx-2 flex justify-center mt-2 mb-2">
        <Box
          // w={[60, '2xl']}
          // h="150px"
          borderWidth="3px"
          borderRadius="lg"
          padding="3"
          overflow="auto"
          className="mt-1 mb-1"
          bg="white"
        >
          <Stat>
            <StatLabel fontSize={['sm', 'lg']}>
              <span className="flex justify-center font-bold ">
                {'ปริมาณการสูญเสียน้ำมันทั้งหมด'}
              </span>
            </StatLabel>
            <StatNumber textColor="blue.400" className="flex justify-center ">
              {(
                parseFloat(oilLossAll[0]?.OilLossFiber) +
                parseFloat(oilLossAll[0]?.OilLossDecanter) +
                parseFloat(oilLossAll[0]?.OilLossWastewater)
              ).toFixed(2)}
            </StatNumber>
            <StatHelpText
              className="flex justify-center"
              fontSize={['sm', 'lg']}
            >
              {' ton/d'}
            </StatHelpText>{' '}
            <div className="flex justify-center sm:text-sm">
              <Badge>
                <i>
                  {
                    'ปริมาณการสูญเสียน้ำมันจากเส้นใย + การสูญเสียน้ำมันใน DECANTER CAKE + การสูญเสียน้ำมันในน้ำเสีย'
                  }
                </i>
              </Badge>
            </div>
          </Stat>
        </Box>
      </div>

      <div className="grid gap-4 grid-cols-4 ">
        {' '}
        <Box
          //w={[32, 48]}
          // h={[48, 36]}
          borderRadius="lg"
          borderWidth="3px"
          className=" ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25"
        >
          <DisplayValueAlert
            oilLossAll={oilLossAll}
            yieldAll={yieldAll}
            valueLoss={fiberLoss}
            name={'ปริมาณการสูญเสียน้ำมันจากเส้นใย'}
          />
        </Box>
        <Box
          // w={[32, 48]}
          // h={[48, 36]}
          borderRadius="lg"
          borderWidth="3px"
          className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25"
        >
          <Stat>
            <StatLabel>{'การสูญเสียน้ำมันจากทะลายปาล์มเปล่า'}</StatLabel>
            <StatNumber textColor="red">{'N/A'}</StatNumber>
            <StatHelpText>
              {/* {dayjs(yieldAll?.lastDate).locale('th').format('DD MMM BBBB')}{' '} */}
            </StatHelpText>
          </Stat>
        </Box>
        <Box
          //w={[32, 48]}
          // h={[48, 36]}
          borderWidth="3px"
          borderRadius="lg"
          className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25"
        >
          <DisplayValueAlert
            oilLossAll={oilLossAll}
            yieldAll={yieldAll}
            valueLoss={decanterLoss}
            name={'การสูญเสียน้ำมันใน DECANTER CAKE'}
          />
        </Box>
        <Box
          // w={[32, 48]}
          // h={[48, 36]}
          borderWidth="3px"
          borderRadius="lg"
          className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25"
        >
          <DisplayValueAlert
            oilLossAll={oilLossAll}
            yieldAll={yieldAll}
            valueLoss={wasteLoss}
            name={'การสูญเสียน้ำมันในน้ำเสีย'}
          />
        </Box>
        <Box
          // w={[32, 48]}
          // h={[48, 36]}
          borderWidth="3px"
          borderRadius="lg"
          className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25 "
        >
          <Stat>
            <StatLabel>{'ปริมาณการผลิต FFB'}</StatLabel>
            <StatNumber textColor="blue.400">
              {yieldAll[0]?.length === 0
                ? Intl.NumberFormat('en-US').format(
                    Number(parseFloat(ffb?.numericStatus)).toFixed(2)
                  )
                : Intl.NumberFormat('en-US').format(
                    Number(
                      parseFloat(yieldAll[0]?.PalmUsed).toFixed(2)
                    ).toFixed(2)
                  )}
              {' ton/d'}
            </StatNumber>
            <StatHelpText>
              {yieldAll[0]?.length === 0
                ? dayjs(ffb?.lastDate).locale('th').format('DD MMM BBBB')
                : dayjs(yieldAll[0]?.date).locale('th').format('DD MMM BBBB')}
            </StatHelpText>
          </Stat>
        </Box>
        <Box
          //  w={[32, 48]}
          // h={[48, 36]}
          borderWidth="3px"
          borderRadius="lg"
          className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25"
        >
          <Stat>
            <StatLabel>{'ปริมาณการผลิต CPO'}</StatLabel>
            <StatNumber textColor="blue.400">
              {yieldAll[0]?.length === 0
                ? Intl.NumberFormat('en-US').format(
                    Number(parseFloat(cpo?.numericStatus)).toFixed(2)
                  )
                : Intl.NumberFormat('en-US').format(
                    Number(parseFloat(yieldAll[0]?.CPO).toFixed(2)).toFixed(2)
                  )}
              {' ton/d'}
            </StatNumber>
            <StatHelpText>
              {yieldAll[0]?.length === 0
                ? dayjs(cpo?.lastDate).locale('th').format('DD MMM BBBB')
                : dayjs(yieldAll[0]?.date).locale('th').format('DD MMM BBBB')}
            </StatHelpText>
          </Stat>
        </Box>
        <Box
          // w={[32, 48]}
          // h={[48, 36]}
          borderWidth="3px"
          borderRadius="lg"
          className="ml-2 mr-2  mt-2 mb-2 p-2 bg-white h-25 "
        >
          <Stat>
            <StatLabel>{'ปริมาณ Yield ที่ได้'}</StatLabel>
            <StatNumber textColor="blue.400">
              {yieldAll[0]?.length === 0
                ? Intl.NumberFormat('en-US').format(
                    Number(parseFloat(yieldCpo?.numericStatus)).toFixed(2)
                  )
                : Intl.NumberFormat('en-US').format(
                    Number(parseFloat(yieldAll[0]?.Yield).toFixed(2)).toFixed(2)
                  )}
              {' ton/d'}
            </StatNumber>
            <StatHelpText>
              {yieldAll[0]?.length === 0
                ? dayjs(yieldCpo?.lastDate).locale('th').format('DD MMM BBBB')
                : dayjs(yieldAll[0]?.date).locale('th').format('DD MMM BBBB')}
            </StatHelpText>
          </Stat>
        </Box>
      </div>
      <hr />
      {/* <div className="my-2 mx-2">
        <span className="">{'กราฟความสัมพันธ์ : '}</span>
        <span className="text-base font-bold font-san mb-0">
          <GraphMultiVar />
        </span>
        {' ton/d'}
        <span></span>
      </div> */}
    </div>
  )
}
