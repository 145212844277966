/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { Button, Box, Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { Controller, useFieldArray } from 'react-hook-form'
import { SpaceBar } from '@mui/icons-material'

export default function CreateGroupPrediction({
  register,
  index,
  control,
  station,
  setValue,
  watch,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `varDetail[${index}].attribute`,
  })

  const sensorMap = []
  _.map(station?.arr, (st, key) => {
    return _.map(st.sensors, (ss) => {
      if (ss?.predictionEnable) {
        sensorMap.push({
          id: ss?._id,
          name: ss?.name,
          station: st?._id,
        })
      }
    })
  })

  return (
    <div>
      {_.map(fields, (eachField, insideIndex) => (
        <div className="relative w-full my-3" key={eachField?.id}>
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            เซนเซอร์ที่ {insideIndex + 1}
          </label>
          <Controller
            name={`varDetail[${index}].attribute[${insideIndex}].sourceId`}
            control={control}
            defaultValue={eachField?.sourceId}
            render={(field) => (
              <Select
                {...field}
                size="sm"
                placeholder="เลือกข้อมูล"
                onChange={(e) => {
                  const foundSensorId = e.target.value
                  const foundSensor = _.find(
                    sensorMap,
                    (each) => each?.id === foundSensorId
                  )
                  setValue(`varDetail[${index}].attribute[${insideIndex}]`, {
                    sourceName: foundSensor?.name,
                    station: foundSensor?.station,
                    sourceId: foundSensor?.id,
                    groupIndex: index,
                  })
                }}
              >
                {_.map(sensorMap, (ss, ssIndex) => (
                  <option value={ss?.id} key={ssIndex}>
                    {ss?.name}
                  </option>
                ))}
              </Select>
            )}
          />

          <Controller
            name={`varDetail[${index}].attribute[${insideIndex}].station`}
            control={control}
            defaultValue={eachField?.station}
            render={() => <div></div>}
          />

          <Controller
            name={`varDetail[${index}].attribute[${insideIndex}].groupIndex`}
            control={control}
            defaultValue={eachField?.groupIndex}
            render={() => <div></div>}
          />

          <Controller
            name={`varDetail[${index}].attribute[${insideIndex}].sourceName`}
            control={control}
            defaultValue={eachField?.sourceName}
            render={() => <div></div>}
          />
        </div>
      ))}
      <Button colorScheme="green" size="sm" onClick={() => append({})}>
        เพิ่มเซนเซอร์ใหม่
      </Button>{' '}
      <Button colorScheme="red" size="sm" onClick={() => remove({})}>
        ลบ
      </Button>
    </div>
  )
}
