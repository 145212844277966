import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../redux/actions'
import { ErrorBoundary } from 'react-error-boundary'

// components

import Sidebar from '../components/common/Sidebar/mainSidebar'
import FooterAdmin from 'components/common/Footers/FooterAdmin'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import Fallback from '../components/error/Fallback'

// views
import SummarizeList from '../views/summarize/SummarizeList'
//import predictionList from '../views/prediction/predictionList'
import PredictionDetail from '../views/prediction/PredictionDetail'
import BiogasDetail from '../views/prediction/BiogasDetail'
import BiomassDetail from '../views/prediction/BiomassDetail'
import DisplayGraphPrediction from '../views/summarize/DisplayGraphPrediction'
import OneSensorGraphPrediction from '../views/summarize/OneSensorGraphPrediction'
import * as UserRole from '../config/UserRoles'

export default function Summarize() {
  const history = useHistory()
  const authorize_role = [
    UserRole.ROLES.USER,
    UserRole.ROLES.GENERAL,
    UserRole.ROLES.ADMIN,
    UserRole.ROLES.SUPER_USER,
  ] // Role ที่ได้รับอนุญาต

  const storage_remember = JSON.parse(window.localStorage.eiot_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log('SUMMARIZE Layout : ACTIVE')
    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.meGet(storage_remember.uid))
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      if (
        (me?.role === authorize_role[2] || me?.role === authorize_role[3]) &&
        system.enable_statistics
      ) {
        setIsLoading(true)
        console.log('ได้รับการอนุญาตเข้าถึง')
      } else {
        console.log('ไม่ได้รับอนุญาต')
        alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้')
        history.push('/')
      }
    }

    return () => {}
  }, [me])
  return isLoading ? (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
          <ErrorBoundary
            FallbackComponent={Fallback}
            onReset={() => {
              history.goBack()
            }}
          >
            <Switch>
              <Route path="/summarize" exact component={SummarizeList} />

              <Route
                path="/summarize/detail"
                exact
                component={PredictionDetail}
              />
              <Route
                path="/summarize/biogasdetail"
                exact
                component={BiogasDetail}
              />
              <Route
                path="/summarize/biomassdetail"
                exact
                component={BiomassDetail}
              />
              <Route
                path="/summarize/detail/graphdetail/:summarizeId/:id"
                exact
                component={DisplayGraphPrediction}
              />

              <Route
                path="/summarize/detail/sensor/:summarizeId/:id/:sensorId"
                exact
                component={OneSensorGraphPrediction}
              />
              {/*
              <Route
                path="/counting-summary/:stationName/:id"
                exact
                component={CountingSummaryDetail}
              /> */}

              <Redirect from="/summarize" to="/summarize" />
            </Switch>
          </ErrorBoundary>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <div>
      <SpinnerLoading />
    </div>
  )
}
